import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
  	<>
      Seite nicht gefunden
	  </>
  );
};

export default NotFound;
