import { ITopic, IGroups } from "../interfaces";

export const GROUPS:Array<IGroups> = [
  {
    topic: "zuhause",
    groups: [
      { name: "haus", image: "133_Haus", coloredImage: "133_Haus" },
      { name: "familie", image: "140_Familie", coloredImage: "140_Familie" },
      { name: "zaehneputzen", image: "144_Zähne_putzen", coloredImage: "144_Zähne_putzen" },
      { name: "sofa", image: "118_Sofa", coloredImage: "118_Sofa" },
      { name: "buntstifte", image: "149_Die Buntstifte", coloredImage: "149_Die Buntstifte" },
      { name: "zeichnen", image: "156_zeichnen", coloredImage: "156_zeichnen" },
    ]
  },
  {
    topic: "koerper",
    groups: [
      { name: "auge", image: "212_Das Auge", coloredImage: "212_Das Auge" },
      { name: "mund", image: "216_Der Mund", coloredImage: "216_Der Mund" },
      { name: "seife", image: "224_Die Seife", coloredImage: "224_Die Seife" },
      { name: "gross", image: "801_gross", coloredImage: "801_gross" },
      { name: "geben", image: "844_geben", coloredImage: "844_geben" },
      { name: "zahlen", image: "1_Finger", coloredImage: "1_Finger" },
    ]
  },
  {
    topic: "kleider",
    groups: [
      { name: "hose", image: "301_Die Hose", coloredImage: "301_Die Hose" },
      { name: "stiefel", image: "330_Die Stiefel", coloredImage: "330_Die Stiefel" },
      { name: "handschuhe", image: "316_Die Handschuhe", coloredImage: "316_Die Handschuhe" },
      { name: "bunt", image: "341_bunt", coloredImage: "341_bunt" },
    ]
  },
  {
    topic: "essen",
    groups: [
      { name: "apfel", image: "401_Der Apfel", coloredImage: "401_Der Apfel" },
      { name: "joghurt", image: "414_Das Joghurt", coloredImage: "414_Das Joghurt" },
      { name: "glace", image: "424_Das Glace", coloredImage: "424_Das Glace" },
      { name: "trinken", image: "435_trinken", coloredImage: "435_trinken" },
      { name: "viel", image: "813_viel", coloredImage: "813_viel" },
    ]
  },
  {
    topic: "natur",
    groups: [
      { name: "blume", image: "502_Die Blume", coloredImage: "502_Die Blume" },
      { name: "hase", image: "508_Der Hase", coloredImage: "508_Der Hase" },
      { name: "frosch", image: "525_Der Frosch", coloredImage: "525_Der Frosch" },
      { name: "oben", image: "827_oben", coloredImage: "827_oben" },
    ]
  },
  {
    topic: "bauernhof",
    groups: [
      { name: "schwein", image: "607_Das Schwein", coloredImage: "607_Das Schwein" },
      { name: "traktor", image: "616_Der Traktor", coloredImage: "616_Der Traktor" },
      { name: "katze", image: "609_Die Katze", coloredImage: "609_Die Katze" },
      { name: "kran", image: "651_Der Kran", coloredImage: "651_Der Kran" },
    ]
  },
  {
    topic: "jahr",
    groups: [
      { name: "sonne", image: "705_Die Sonne", coloredImage: "705_Die Sonne" },
      { name: "geschenk", image: "719_Das Geschenk", coloredImage: "719_Das Geschenk" },
      { name: "stern", image: "728_Der Stern", coloredImage: "728_Der Stern" },
      { name: "ball", image: "730_Der Ball", coloredImage: "730_Der Ball" },
    ]
  }
];

export const WORDS:Array<ITopic> = [
  {
    name: "zuhause",
    words: [
      { spriteId: "101", image: "101_Mutter_Frau", word: "die Mutter / die Frau", group: "familie" },
      { spriteId: "102", image: "102_Vater_der_Mann", word: "der Vater / der Mann", group: "familie" },
      { spriteId: "103", image: "103_Bruder", word: "der Bruder", group: "familie" },
      { spriteId: "104", image: "104_Schwester", word: "die Schwester", group: "familie" },
      { spriteId: "105", image: "105_Molnar", word: "Ich, Molnar", group: "familie" },
      { spriteId: "106", image: "106_Grossmutter", word: "die Grossmutter", group: "familie" },
      { spriteId: "107", image: "107_Grossvater", word: "der Grossvater", group: "familie" },
      { spriteId: "108", image: "108_Tür", word: "die Tür", group: "haus" },
      { spriteId: "109", image: "109_Fenster", word: "das Fenster", group: "haus" },
      { spriteId: "110", image: "110_Küche", word: "die Küche", group: "haus" },
      { spriteId: "111", image: "111_Badezimmer", word: "das Badezimmer", group: "zaehneputzen" },
      { spriteId: "112", image: "112_Schlafzimmer", word: "das Schlafzimmer", group: "haus" },
      { spriteId: "113", image: "113_Wohnzimmer", word: "das Wohnzimmer", group: "haus" },
      { spriteId: "114", image: "114_Dach", word: "das Dach", group: "haus" },
      { spriteId: "115", image: "115_Garten", word: "der Garten", group: "haus" },
      { spriteId: "116", image: "116_Stuhl", word: "der Stuhl", group: "haus" },
      { spriteId: "117", image: "117_Tisch", word: "der Tisch", group: "haus" },
      { spriteId: "118", image: "118_Sofa", word: "das Sofa", group: "sofa" },
      { spriteId: "119", image: "119_Schrank", word: "der Schrank", group: "haus" },
      { spriteId: "120", image: "120_WC", word: "das WC", group: "zaehneputzen" },
      { spriteId: "121", image: "121_Badewanne", word: "die Badewanne", group: "zaehneputzen" },
      { spriteId: "122", image: "122_Dusche", word: "die Dusche", group: "zaehneputzen" },
      { spriteId: "123", image: "123_Herd", word: "der Herd", group: "sofa" },
      { spriteId: "124", image: "124_Kühlschrank", word: "der Kühlschrank", group: "sofa" },
      { spriteId: "125", image: "125_Garage", word: "die Garage", group: "sofa" },
      { spriteId: "126", image: "126_Teppich", word: "der Teppich", group: "zaehneputzen" },
      { spriteId: "127", image: "127_Bett", word: "das Bett", group: "zaehneputzen" },
      { spriteId: "128", image: "128_Kissen", word: "das Kissen", group: "zaehneputzen" },
      { spriteId: "129", image: "129_Bettdecke", word: "die Bettdecke", group: "zaehneputzen" },
      { spriteId: "130", image: "130_Lampe", word: "die Lampe", group: "zaehneputzen" },
      { spriteId: "131", image: "131_Buch", word: "das Buch", group: "sofa" },
      { spriteId: "132", image: "132_Computer", word: "der Computer", group: "sofa" },
      { spriteId: "133", image: "133_Haus", word: "das Haus", group: "haus" },
      { spriteId: "134", image: "134_Kuchen", word: "der Kuchen", group: "sofa" },
      { spriteId: "135", image: "135_Der Backofen", word: "der Backofen", group: "sofa" },
      { spriteId: "136", image: "136_Junge", word: "der Junge", group: "familie" },
      { spriteId: "137", image: "137_Mädchen", word: "das Mädchen", group: "familie" },
      { spriteId: "138", image: "138_Kleider", word: "die Kleider", group: "sofa" },
      { spriteId: "139", image: "139_Regal", word: "das Regal", group: "sofa" },
      { spriteId: "140", image: "140_Familie", word: "die Familie", group: "familie" },
      { spriteId: "141", image: "141_Eltern", word: "die Eltern", group: "familie" },
      { spriteId: "142", image: "142_Geburtstag", word: "der Geburtstag", group: "sofa" },
      { spriteId: "143", image: "143_Hände_waschen", word: "Hände waschen", group: "zaehneputzen" },
      { spriteId: "144", image: "144_Zähne_putzen", word: "Zähne putzen", group: "zaehneputzen" },
      { spriteId: "145", image: "145_wohnen", word: "wohnen", group: "familie" },
      { spriteId: "146", image: "146_Die Schere", word: "die Schere", group: "buntstifte" },
      { spriteId: "147", image: "147_Der Leim", word: "der Leim", group: "buntstifte" },
      { spriteId: "148", image: "148_Das Blatt Papier", word: "das Blatt Papier", group: "buntstifte" },
      { spriteId: "149", image: "149_Die Buntstifte", word: "die Buntstifte", group: "buntstifte" },
      { spriteId: "150", image: "150_Der Bleistift", word: "der Bleistift", group: "buntstifte" },
      { spriteId: "151", image: "151_Der Pinsel", word: "der Pinsel", group: "buntstifte" },
      { spriteId: "152", image: "152_Die Wasserfarben", word: "die Wasserfarben", group: "buntstifte" },
      { spriteId: "153", image: "153_Der Spitzer", word: "der Spitzer", group: "buntstifte" },
      { spriteId: "154", image: "154_Der Würfel", word: "der Würfel", group: "buntstifte" },
      { spriteId: "155", image: "155_Das Spiel", word: "das Spiel", group: "buntstifte" },
      { spriteId: "156", image: "156_zeichnen", word: "zeichnen", group: "zeichnen" },
      { spriteId: "157", image: "157_spielen", word: "spielen", group: "zeichnen" },
      { spriteId: "158", image: "158_basteln", word: "basteln", group: "zeichnen" },
      { spriteId: "159", image: "159_schneiden", word: "schneiden", group: "zeichnen" },
      { spriteId: "160", image: "160_helfen", word: "helfen", group: "zeichnen" },
      { spriteId: "161", image: "161_singen", word: "singen", group: "zeichnen" },
      { spriteId: "162", image: "162_lernen", word: "lernen", group: "zeichnen" },
      { spriteId: "163", image: "163_bauen", word: "bauen", group: "zeichnen" },
      { spriteId: "164", image: "164_turnen", word: "turnen", group: "zeichnen" },
      { spriteId: "165", image: "165_tanzen", word: "tanzen", group: "zeichnen" },
      { spriteId: "166", image: "166_malen", word: "malen", group: "zeichnen" },
      { spriteId: "167", image: "167_kleben", word: "kleben", group: "zeichnen" },
      { spriteId: "852", image: "852_spitzig", word: "spitzig", group: "zeichnen" },
    ]
  },
  {
    name: "koerper",
    words: [
      { spriteId: "201", image: "201_Der Kopf", word: "der Kopf", group: "auge" },
      { spriteId: "202", image: "202_Der Hals", word: "der Hals", group: "auge" },
      { spriteId: "203", image: "203_Der Arm", word: "der Arm", group: "auge" },
      { spriteId: "204", image: "204_Das Bein", word: "das Bein", group: "auge" },
      { spriteId: "205", image: "205_Der Fuss", word: "der Fuss", group: "auge" },
      { spriteId: "206", image: "206_Der Bauch", word: "der Bauch", group: "auge" },
      { spriteId: "207", image: "207_Der Rücken", word: "der Rücken", group: "auge" },
      { spriteId: "208", image: "208_Der Po", word: "der Po", group: "auge" },
      { spriteId: "209", image: "209_Die Hand", word: "die Hand", group: "auge" },
      { spriteId: "210", image: "210_Der Finger", word: "der Finger", group: "auge" },
      { spriteId: "211", image: "211_Das Ohr", word: "das Ohr", group: "mund" },
      { spriteId: "212", image: "212_Das Auge", word: "das Auge", group: "auge" },
      { spriteId: "213", image: "213_Das Gesicht", word: "das Gesicht", group: "mund" },
      { spriteId: "214", image: "214_Die Haare", word: "die Haare", group: "mund" },
      { spriteId: "215", image: "215_Die Zunge", word: "die Zunge", group: "mund" },
      { spriteId: "216", image: "216_Der Mund", word: "der Mund", group: "mund" },
      { spriteId: "217", image: "217_Die Nase", word: "die Nase", group: "mund" },
      { spriteId: "218", image: "218_Das Nastuch", word: "das Nastuch", group: "mund" },
      { spriteId: "219", image: "219_Der Zahn", word: "der Zahn", group: "seife" },
      { spriteId: "220", image: "220_Die Zahnbürste", word: "die Zahnbürste", group: "seife" },
      { spriteId: "221", image: "221_Die Zahnpasta", word: "die Zahnpasta", group: "seife" },
      { spriteId: "222", image: "222_Das Blut", word: "das Blut", group: "seife" },
      { spriteId: "223", image: "223_Das Pflaster", word: "das Pflaster", group: "seife" },
      { spriteId: "224", image: "224_Die Seife", word: "die Seife", group: "seife" },
      { spriteId: "225", image: "225_Das Handtuch", word: "das Handtuch", group: "seife" },
      { spriteId: "226", image: "226_Lachen", word: "lachen", group: "mund" },
      { spriteId: "227", image: "227_Weinen", word: "weinen", group: "mund" },
      { spriteId: "819", image: "819_trocken", word: "trocken", group: "seife" },
      { spriteId: "820", image: "820_nass", word: "nass", group: "seife" },
      { spriteId: "838", image: "838_gehen", word: "gehen", group: "seife" },
      { spriteId: "839", image: "839_schlafen", word: "schlafen", group: "seife" },
      { spriteId: "801", image: "801_gross", word: "gross", group: "gross" },
      { spriteId: "802", image: "802_klein", word: "klein", group: "gross" },
      { spriteId: "803", image: "803_schön", word: "schön", group: "gross" },
      { spriteId: "804", image: "804_hässlich", word: "hässlich", group: "gross" },
      { spriteId: "811", image: "811_rund", word: "rund", group: "gross" },
      { spriteId: "812", image: "812_eckig", word: "eckig", group: "gross" },
      { spriteId: "815", image: "815_lang", word: "lang", group: "gross" },
      { spriteId: "816", image: "816_kurz", word: "kurz", group: "gross" },
      { spriteId: "817", image: "817_stark", word: "stark", group: "gross" },
      { spriteId: "818", image: "818_schwach", word: "schwach", group: "gross" },
      { spriteId: "840", image: "840_zählen", word: "zählen", group: "gross" },
      { spriteId: "841", image: "841_riechen", word: "riechen", group: "geben" },
      { spriteId: "842", image: "842_sehen", word: "sehen", group: "geben" },
      { spriteId: "843", image: "843_hören", word: "hören", group: "geben" },
      { spriteId: "844", image: "844_geben", word: "geben", group: "geben" },
      { spriteId: "845", image: "845_rufen", word: "rufen", group: "geben" },
      { spriteId: "846", image: "846_sprechen", word: "sprechen", group: "geben" },
      { spriteId: "850", image: "850_schreien", word: "schreien", group: "geben" },
      { spriteId: "851", image: "851_warten", word: "warten", group: "geben" },
      { spriteId: "854", image: "854_lustig", word: "lustig", group: "geben" },
      { spriteId: "859", image: "859_kalt", word: "kalt (empfinden)", group: "geben" },
      { spriteId: "860", image: "860_warm", word: "warm (empfinden)", group: "geben" },
      { spriteId: "260", image: "1_Finger", word: "1, eins", group: "zahlen" },
      { spriteId: "261", image: "2_Finger", word: "2, zwei", group: "zahlen" },
      { spriteId: "262", image: "3_Finger", word: "3, drei", group: "zahlen" },
      { spriteId: "263", image: "4_Finger", word: "4, vier", group: "zahlen" },
      { spriteId: "264", image: "5_Finger", word: "5, fünf", group: "zahlen" },
      { spriteId: "265", image: "6_Finger", word: "6, sechs", group: "zahlen" },
      { spriteId: "266", image: "7_Finger", word: "7, sieben", group: "zahlen" },
      { spriteId: "228", image: "226_Lachen", word: "glücklich", group: "mund" },
      { spriteId: "229", image: "227_Weinen", word: "traurig", group: "mund" },
    ]
  },
  {
    name: "kleider",
    words: [
      { spriteId: "301", image: "301_Die Hose", word: "Die Hose", group: "hose" },
      { spriteId: "302", image: "302_Die Jacke", word: "die Jacke", group: "hose" },
      { spriteId: "303", image: "303_Die Mütze", word: "die Mütze", group: "hose" },
      { spriteId: "304", image: "304_Die Schuhe", word: "die Schuhe", group: "hose" },
      { spriteId: "305", image: "305_Die Socken", word: "die Socken", group: "hose" },
      { spriteId: "306", image: "306_Die Finken", word: "die Finken", group: "hose" },
      { spriteId: "307", image: "307_Der Pullover", word: "der Pullover", group: "hose" },
      { spriteId: "308", image: "308_Das T-Shirt", word: "das T-Shirt", group: "hose" },
      { spriteId: "309", image: "309_Das Pyjama", word: "das Pyjama", group: "hose" },
      { spriteId: "310", image: "310_Die Brille", word: "die Brille", group: "hose" },
      { spriteId: "311", image: "311_Die Tasche", word: "die Tasche", group: "hose" },
      { spriteId: "312", image: "312_Die Strumpfhose", word: "die Strumpfhose", group: "handschuhe" },
      { spriteId: "313", image: "313_Die Unterhose", word: "die Unterhose", group: "stiefel" },
      { spriteId: "314", image: "314_Das Unterhemd", word: "das Unterhemd", group: "stiefel" },
      { spriteId: "315", image: "315_Das Kleid", word: "das Kleid", group: "hose" },
      { spriteId: "316", image: "316_Die Handschuhe", word: "die Handschuhe", group: "handschuhe" },
      { spriteId: "317", image: "317_Der Schal", word: "der Schal", group: "handschuhe" },
      { spriteId: "318", image: "318_Der Skianzug", word: "der Skianzug", group: "handschuhe" },
      { spriteId: "319", image: "319_Der Reissverschluss", word: "der Reissverschluss", group: "handschuhe" },
      { spriteId: "320", image: "320_Der Knopf", word: "der Knopf", group: "handschuhe" },
      { spriteId: "321", image: "321_anziehen", word: "anziehen", group: "stiefel" },
      { spriteId: "322", image: "322_ausziehen", word: "ausziehen", group: "stiefel" },
      { spriteId: "323", image: "323_gepunktet", word: "gepunktet", group: "handschuhe" },
      { spriteId: "324", image: "324_gestreift", word: "gestreift", group: "handschuhe" },
      { spriteId: "325", image: "325_Der Rucksack", word: "der Rucksack", group: "stiefel" },
      { spriteId: "326", image: "326_Die Turnhose", word: "die Turnhose", group: "stiefel" },
      { spriteId: "327", image: "327_Das Badekleid", word: "das Badekleid", group: "stiefel" },
      { spriteId: "328", image: "328_Die Badehose", word: "die Badehose", group: "stiefel" },
      { spriteId: "329", image: "329_Die Regenjacke", word: "die Regenjacke", group: "stiefel" },
      { spriteId: "330", image: "330_Die Stiefel", word: "die Stiefel", group: "stiefel" },
      { spriteId: "331", image: "331_Der Regenschirm", word: "der Regenschirm", group: "stiefel" },
      { spriteId: "332", image: "332_rot", word: "rot", group: "bunt" },
      { spriteId: "333", image: "333_gelb", word: "gelb", group: "bunt" },
      { spriteId: "334", image: "334_grün", word: "grün", group: "bunt" },
      { spriteId: "335", image: "335_blau", word: "blau", group: "bunt" },
      { spriteId: "336", image: "336_orange", word: "orange", group: "bunt" },
      { spriteId: "337", image: "337_braun", word: "braun", group: "bunt" },
      { spriteId: "338", image: "338_schwarz", word: "schwarz", group: "bunt" },
      { spriteId: "339", image: "339_weiss", word: "weiss", group: "bunt" },
      { spriteId: "340", image: "340_lila", word: "lila", group: "bunt" },
      { spriteId: "341", image: "341_bunt", word: "farbig", group: "bunt" },
      { spriteId: "342", image: "342_grau", word: "grau", group: "bunt" },
      { spriteId: "343", image: "343_Die Kapuze", word: "die Kapuze", group: "stiefel" },
      { spriteId: "344", image: "344_rot gelb grün", word: "Rot, Gelb, Grün", group: "bunt" },
      { spriteId: "807", image: "807_hoch", word: "hoch", group: "handschuhe" },
      { spriteId: "808", image: "808_tief", word: "tief", group: "handschuhe" },
      { spriteId: "809", image: "809_hart", word: "hart", group: "handschuhe" },
      { spriteId: "810", image: "810_weich", word: "weich", group: "handschuhe" },
    ]
  },
  {
    name: "essen",
    words: [
      { spriteId: "401", image: "401_Der Apfel", word: "der Apfel", group: "apfel" },
      { spriteId: "402", image: "402_Die Birne", word: "die Birne", group: "apfel" },
      { spriteId: "403", image: "403_Die Banane", word: "die Banane", group: "apfel" },
      { spriteId: "404", image: "404_Die Erdbeere", word: "die Erdbeeren", group: "apfel" },
      { spriteId: "405", image: "405_Die Orange", word: "die Orange", group: "apfel" },
      { spriteId: "406", image: "406_Die Kartoffel", word: "die Kartoffel", group: "apfel" },
      { spriteId: "407", image: "407_Die Karotte", word: "die Karotte", group: "apfel" },
      { spriteId: "408", image: "408_Der Salat", word: "der Salat", group: "apfel" },
      { spriteId: "409", image: "409_Die Tomate", word: "die Tomate", group: "apfel" },
      { spriteId: "410", image: "410_Die Zwiebel", word: "die Zwiebel", group: "apfel" },
      { spriteId: "411", image: "411_Die Wurst", word: "die Wurst", group: "apfel" },
      { spriteId: "412", image: "412_Das Brot", word: "das Brot", group: "joghurt" },
      { spriteId: "413", image: "413_Das Brötchen", word: "das Brötchen", group: "joghurt" },
      { spriteId: "414", image: "414_Das Joghurt", word: "das Joghurt", group: "joghurt" },
      { spriteId: "415", image: "415_Die Butter", word: "die Butter", group: "joghurt" },
      { spriteId: "416", image: "416_Der Saft", word: "der Saft", group: "joghurt" },
      { spriteId: "417", image: "417_Das Wasser", word: "das Wasser", group: "joghurt" },
      { spriteId: "418", image: "418_Die Chips", word: "die Chips", group: "joghurt" },
      { spriteId: "419", image: "419_Das Salz", word: "das Salz", group: "joghurt" },
      { spriteId: "420", image: "420_Der Zucker", word: "der Zucker", group: "joghurt" },
      { spriteId: "421", image: "421_Der Tee", word: "der Tee", group: "joghurt" },
      { spriteId: "422", image: "422_Die Konfitüre", word: "die Konfitüre", group: "joghurt" },
      { spriteId: "423", image: "423_Die Schokolade", word: "die Schokolade", group: "glace" },
      { spriteId: "424", image: "424_Das Glace", word: "das Glace", group: "glace" },
      { spriteId: "425", image: "425_Die Pizza", word: "die Pizza", group: "glace" },
      { spriteId: "426", image: "426_Die Spaghetti", word: "die Spaghetti", group: "glace" },
      { spriteId: "427", image: "427_Der Teller", word: "der Teller", group: "glace" },
      { spriteId: "428", image: "428_Die Tasse", word: "die Tasse", group: "glace" },
      { spriteId: "429", image: "429_Das Glas", word: "das Glas", group: "glace" },
      { spriteId: "430", image: "430_Der Löffel", word: "der Löffel", group: "glace" },
      { spriteId: "431", image: "431_Die Gabel", word: "die Gabel", group: "glace" },
      { spriteId: "432", image: "432_Das Messer", word: "das Messer", group: "glace" },
      { spriteId: "433", image: "433_kochen", word: "kochen", group: "glace" },
      { spriteId: "434", image: "434_essen", word: "essen", group: "trinken" },
      { spriteId: "435", image: "435_trinken", word: "trinken", group: "trinken" },
      { spriteId: "436", image: "436_Der Hunger", word: "der Hunger", group: "trinken" },
      { spriteId: "437", image: "437_Die Schale", word: "die Schale", group: "trinken" },
      { spriteId: "438", image: "438_schmeckt gut", word: "schmeckt gut", group: "trinken" },
      { spriteId: "439", image: "439_Die Suppe", word: "die Suppe", group: "trinken" },
      { spriteId: "440", image: "440_süss", word: "süss", group: "trinken" },
      { spriteId: "441", image: "441_scharf", word: "scharf", group: "trinken" },
      { spriteId: "442", image: "442_heiss", word: "heiss", group: "trinken" },
      { spriteId: "443", image: "443_kalt", word: "kalt", group: "trinken" },
      { spriteId: "444", image: "444_schneiden", word: "schneiden", group: "trinken" },
      { spriteId: "813", image: "813_viel", word: "viel", group: "viel" },
      { spriteId: "814", image: "814_wenig", word: "wenig", group: "viel" },
      { spriteId: "821", image: "821_gut", word: "gut", group: "viel" },
      { spriteId: "822", image: "822_schlecht", word: "schlecht", group: "viel" },
      { spriteId: "830", image: "830_krumm", word: "krumm", group: "viel" },
      { spriteId: "831", image: "830a_gerade", word: "gerade", group: "viel" },
      { spriteId: "855", image: "855_schmutzig", word: "schmutzig", group: "viel" },
      { spriteId: "856", image: "856_sauber", word: "sauber", group: "viel" },
      { spriteId: "857", image: "857_dünn", word: "dünn", group: "viel" },
      { spriteId: "858", image: "858_dick", word: "dick", group: "viel" },
    ]
  },
  {
    name: "natur",
    words: [
      { spriteId: "501", image: "501_Der Baum", word: "der Baum", group: "blume" },
      { spriteId: "502", image: "502_Die Blume", word: "die Blume", group: "blume" },
      { spriteId: "503", image: "503_Das Blatt", word: "das Blatt", group: "blume" },
      { spriteId: "504", image: "504_Die Erde", word: "die Erde", group: "blume" },
      { spriteId: "505", image: "505_Der Pilz", word: "der Pilz", group: "blume" },
      { spriteId: "506", image: "506_Der Wald", word: "der Wald", group: "blume" },
      { spriteId: "507", image: "507_Der Igel", word: "der Igel", group: "blume" },
      { spriteId: "508", image: "508_Der Hase", word: "der Hase", group: "hase" },
      { spriteId: "509", image: "509_Das Eichhörnchen", word: "das Eichhörnchen", group: "blume" },
      { spriteId: "510", image: "510_Der Fuchs", word: "der Fuchs", group: "blume" },
      { spriteId: "511", image: "511_Der Vogel", word: "der Vogel", group: "blume" },
      { spriteId: "512", image: "512_Die Maus", word: "die Maus", group: "hase" },
      { spriteId: "513", image: "513_Das Nest", word: "das Nest", group: "hase" },
      { spriteId: "514", image: "514_Die Schnecke", word: "die Schnecke", group: "hase" },
      { spriteId: "515", image: "515_Die Spinne", word: "die Spinne", group: "hase" },
      { spriteId: "516", image: "516_Die Ameise", word: "die Ameise", group: "hase" },
      { spriteId: "517", image: "517_Die Biene", word: "die Biene", group: "frosch" },
      { spriteId: "518", image: "518_Der Wurm", word: "der Wurm", group: "hase" },
      { spriteId: "519", image: "519_Der Käfer", word: "der Käfer", group: "hase" },
      { spriteId: "520", image: "520_Der Schmetterling", word: "der Schmetterling", group: "hase" },
      { spriteId: "521", image: "521_Die Wiese", word: "die Wiese", group: "frosch" },
      { spriteId: "522", image: "522_Der Fluss", word: "der Fluss", group: "frosch" },
      { spriteId: "523", image: "523_Der See", word: "der See", group: "frosch" },
      { spriteId: "524", image: "524_Der Fisch", word: "der Fisch", group: "frosch" },
      { spriteId: "525", image: "525_Der Frosch", word: "der Frosch", group: "frosch" },
      { spriteId: "526", image: "526_Die Ente", word: "die Ente", group: "frosch" },
      { spriteId: "527", image: "527_Die Schlange", word: "die Schlange", group: "hase" },
      { spriteId: "528", image: "528_Der Ast", word: "der Ast", group: "hase" },
      { spriteId: "529", image: "529_Der Stein", word: "der Stein", group: "hase" },
      { spriteId: "530", image: "530_Die Nuss", word: "die Nuss", group: "frosch" },
      { spriteId: "531", image: "531_Der Berg", word: "der Berg", group: "blume" },
      { spriteId: "532", image: "532_Der Stachel", word: "der Stachel", group: "frosch" },
      { spriteId: "533", image: "533_Der Honig", word: "der Honig", group: "frosch" },
      { spriteId: "534", image: "534_Der Boden", word: "der Boden", group: "blume" },
      { spriteId: "535", image: "535_Die Schale", word: "die Schale", group: "frosch" },
      { spriteId: "536", image: "536_Die Blumen", word: "die Blumen", group: "frosch" },
      { spriteId: "805", image: "805_schnell", word: "schnell", group: "oben" },
      { spriteId: "806", image: "806_langsam", word: "langsam", group: "oben" },
      { spriteId: "825", image: "825_hinten", word: "hinten", group: "oben" },
      { spriteId: "826", image: "826_vorne", word: "vorne", group: "oben" },
      { spriteId: "827", image: "827_oben", word: "oben", group: "oben" },
      { spriteId: "828", image: "828_unten", word: "unten", group: "oben" },
      { spriteId: "829", image: "829_auf", word: "auf", group: "oben" },
      { spriteId: "830", image: "829a_unter", word: "unter", group: "oben" },
      { spriteId: "834", image: "834_fliegen", word: "fliegen", group: "oben" },
      { spriteId: "835", image: "835_kriechen", word: "kriechen", group: "oben" },
      { spriteId: "836", image: "836_hüpfen", word: "hüpfen", group: "oben" },
      { spriteId: "837", image: "837_schwimmen", word: "schwimmen", group: "oben" },
    ]
  },
  {
    name: "bauernhof",
    words: [
      { spriteId: "601", image: "601_Die Kuh", word: "die Kuh", group: "schwein" },
      { spriteId: "602", image: "602_Das Huhn", word: "das Huhn", group: "schwein" },
      { spriteId: "603", image: "603_Der Hahn", word: "der Hahn", group: "schwein" },
      { spriteId: "604", image: "604_Das Küken", word: "das Küken", group: "schwein" },
      { spriteId: "605", image: "605_Die Ziege", word: "die Ziege", group: "schwein" },
      { spriteId: "606", image: "606_Das Schaf", word: "das Schaf", group: "schwein" },
      { spriteId: "607", image: "607_Das Schwein", word: "das Schwein", group: "schwein" },
      { spriteId: "608", image: "608_Der Hund", word: "der Hund", group: "katze" },
      { spriteId: "609", image: "609_Die Katze", word: "die Katze", group: "katze" },
      { spriteId: "610", image: "610_Der Esel", word: "der Esel", group: "schwein" },
      { spriteId: "611", image: "611_Das Pferd", word: "das Pferd", group: "schwein" },
      { spriteId: "612", image: "612_Das Fell", word: "das Fell", group: "katze" },
      { spriteId: "613", image: "613_Das Futter", word: "das Futter", group: "traktor" },
      { spriteId: "614", image: "614_Der Stall", word: "der Stall", group: "traktor" },
      { spriteId: "615", image: "615_Das Ei", word: "das Ei", group: "schwein" },
      { spriteId: "616", image: "616_Der Traktor", word: "der Traktor", group: "traktor" },
      { spriteId: "617", image: "617_Der Zaun", word: "der Zaun", group: "traktor" },
      { spriteId: "618", image: "618_Der Käse", word: "der Käse", group: "traktor" },
      { spriteId: "619", image: "619_Die Milch", word: "die Milch", group: "traktor" },
      { spriteId: "620", image: "620_Das Bauernhaus", word: "das Bauernhaus", group: "traktor" },
      { spriteId: "621", image: "621_Das Gras", word: "das Gras", group: "traktor" },
      { spriteId: "622", image: "622_Die Tiere", word: "viele Tiere", group: "traktor" },
      { spriteId: "650", image: "650_Die Baustelle", word: "die Baustelle", group: "kran" },
      { spriteId: "651", image: "651_Der Kran", word: "der Kran", group: "kran" },
      { spriteId: "652", image: "652_Das Auto", word: "das Auto", group: "kran" },
      { spriteId: "653", image: "653_Das Velo", word: "das Velo", group: "kran" },
      { spriteId: "654", image: "654_Der Bus", word: "der Bus", group: "kran" },
      { spriteId: "655", image: "655_Das Motorrad", word: "das Motorrad", group: "kran" },
      { spriteId: "656", image: "656_Das Flugzeug", word: "das Flugzeug", group: "kran" },
      { spriteId: "657", image: "657_Der Zug", word: "der Zug", group: "kran" },
      { spriteId: "658", image: "658_Das Schiff", word: "das Schiff", group: "kran" },
      { spriteId: "659", image: "659_Die Strasse", word: "die Strasse", group: "kran" },
      { spriteId: "660", image: "660_Der Bahnhof", word: "der Bahnhof", group: "kran" },
      { spriteId: "661", image: "661_warten_schauen_gehen", word: "warten, schauen, hören, gehen", group: "kran" },
      { spriteId: "662", image: "662_Das Trottoir", word: "das Trottoir", group: "kran" },
      { spriteId: "663", image: "663_Der Bagger", word: "der Bagger", group: "kran" },
      { spriteId: "823", image: "823_laut", word: "laut", group: "katze" },
      { spriteId: "824", image: "824_leise", word: "leise", group: "katze" },
      { spriteId: "847", image: "847_bellen", word: "bellen", group: "katze" },
      { spriteId: "848", image: "848_miauen", word: "miauen", group: "katze" },
      { spriteId: "849", image: "849_fahren", word: "fahren", group: "kran" },
      { spriteId: "853", image: "853_lieb", word: "lieb", group: "katze" },
      { spriteId: "854", image: "853a_böse", word: "böse", group: "katze" },
    ]
  },
  {
    name: "jahr",
    words: [
      { spriteId: "701", image: "701_Der Sommer", word: "der Sommer", group: "sonne" },
      { spriteId: "702", image: "702_Der Herbst", word: "der Herbst", group: "sonne" },
      { spriteId: "703", image: "703_Der Winter", word: "der Winter", group: "sonne" },
      { spriteId: "704", image: "704_Der Frühling", word: "der Frühling", group: "sonne" },
      { spriteId: "705", image: "705_Die Sonne", word: "die Sonne", group: "sonne" },
      { spriteId: "706", image: "706_Die Wolke", word: "die Wolke", group: "sonne" },
      { spriteId: "707", image: "707_Der Regen", word: "der Regen", group: "sonne" },
      { spriteId: "708", image: "708_Der Schnee", word: "der Schnee", group: "sonne" },
      { spriteId: "709", image: "709_Der Wind", word: "der Wind", group: "sonne" },
      { spriteId: "710", image: "710_Der Mond", word: "der Mond", group: "sonne" },
      { spriteId: "711", image: "711_Der Nebel", word: "der Nebel", group: "geschenk" },
      { spriteId: "712", image: "712_Der Regenbogen", word: "der Regenbogen", group: "geschenk" },
      { spriteId: "713", image: "713_Das Gewitter", word: "das Gewitter", group: "geschenk" },
      { spriteId: "714", image: "714_Die Schneeflocke", word: "die Schneeflocke", group: "geschenk" },
      { spriteId: "715", image: "715_Der Osterhase", word: "der Osterhase", group: "geschenk" },
      { spriteId: "716", image: "716_Das Osterei", word: "das Osterei", group: "geschenk" },
      { spriteId: "717", image: "717_Die Weihnachten", word: "die Weihnachten", group: "geschenk" },
      { spriteId: "718", image: "718_Der Weihnachtsbaum", word: "der Weihnachtsbaum", group: "geschenk" },
      { spriteId: "719", image: "719_Das Geschenk", word: "das Geschenk", group: "geschenk" },
      { spriteId: "720", image: "720_Der Samiklaus", word: "der Samiklaus", group: "geschenk" },
      { spriteId: "721", image: "721_Der Engel", word: "der Engel", group: "geschenk" },
      { spriteId: "722", image: "722_Der Schneemann", word: "der Schneemann", group: "stern" },
      { spriteId: "723", image: "723_Der Schneeball", word: "der Schneeball", group: "stern" },
      { spriteId: "724", image: "724_Der Schlitten", word: "der Schlitten", group: "stern" },
      { spriteId: "725", image: "725_Die Schaufel", word: "die Schaufel", group: "stern" },
      { spriteId: "726", image: "726_Die Ski", word: "die Ski", group: "stern" },
      { spriteId: "727", image: "727_Die Schlittschuhe", word: "die Schlittschuhe", group: "stern" },
      { spriteId: "728", image: "728_Der Stern", word: "der Stern", group: "stern" },
      { spriteId: "729", image: "729_Die Kerze", word: "die Kerze", group: "stern" },
      { spriteId: "730", image: "730_Der Ball", word: "der Ball", group: "ball" },
      { spriteId: "731", image: "731_Der Sandkasten", word: "der Sandkasten", group: "ball" },
      { spriteId: "732", image: "732_spielen", word: "spielen", group: "ball" },
      { spriteId: "733", image: "733_bauen", word: "bauen", group: "ball" },
      { spriteId: "734", image: "734_rennen", word: "rennen", group: "ball" },
      { spriteId: "735", image: "735_spazieren", word: "spazieren", group: "ball" },
      { spriteId: "736", image: "736_Der Himmel", word: "der Himmel", group: "stern" },
      { spriteId: "737", image: "737_Das Eis", word: "das Eis", group: "stern" },
      { spriteId: "738", image: "738_Der Tag", word: "der Tag", group: "ball" },
      { spriteId: "739", image: "739_Die Nacht", word: "die Nacht", group: "ball" },
      { spriteId: "831", image: "831_an", word: "an", group: "ball" },
      { spriteId: "834", image: "831a_aus", word: "aus", group: "ball" },
      { spriteId: "832", image: "832_hell", word: "hell", group: "ball" },
      { spriteId: "833", image: "833_dunkel", word: "dunkel", group: "ball" },
    ]
  },
];
