import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

// interfaces
import { IWord } from "../../interfaces";
import { WORDS } from "../../constants/Words";
import { GAME_LENGTH } from "../../constants";

// helpers
import { shuffleArray } from "../../features/helpers";

// assets
import { ReactComponent as Close } from "../../assets/icons/Close.svg";
import { ReactComponent as Replay } from "../../assets/icons/Replay.svg";

// Components
import { useAudio } from "../../components/WordsAudio";

// Styles
import { Mobile, TabletAndDesktop } from "../../app/mediaQueries";
import styled from 'styled-components';
const Main = styled.div`
    width: 100%;
`;
const Header = styled.div`
  position: fixed;
  height: 8vh;
  width: 100%;
  > div {
    padding: 2vh 2vh 0 2vh;
  }
`;
const CloseContainer = styled.div`
  float: right;
  width: 40px;
  svg {
    transform: scale(0.9);
    &:hover {
      transform: scale(1);
    }
  }
`;
const ReplayContainer = styled.div`
  float: left;
  width: 40px;
`;
const MemoryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 25%);
  min-height: 85vh;
  padding-top: 7vh;
  padding-bottom: 4vh;
  padding-left: 1vh;
`;
const MemoryContainerMobile = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 33%);
  min-height: 85vh;
  padding-top: 7vh;
  padding-bottom: 4vh;
  padding-left: 1vh;
`;
const MemoryCardContainer = styled.div`
  cursor: pointer;
  > div {
    margin: 1vh 1vh 0 0;
    > img {
      vertical-align: middle;
      max-width: 100%;
    }
  }
`;

const Memory = () => {
    const { topic } = useParams();
    const BLANK_CARD = "memory_background";
    const [imagesArray, setImagesArray] = useState(new Array<IWord>());
    const [cardsChosen, setCardsChosen] = useState(new Array<IWord>());
    const [cardsChosenIds, setCardsChosenIds] = useState(new Array<number>());
    const [openCards, setOpenCards] = useState(new Array<IWord>());
    const data = WORDS.find(words => words.name === topic);
    const words = data ? data.words : WORDS[0].words

    const [audioStatus, replay, stopAudio, changeWord] = useAudio("");

    function createCardBoard() {
        const imagesGenerated = shuffleArray(words).slice(0, GAME_LENGTH.MEMORY);
        const shuffledArray = shuffleArray(imagesGenerated?.concat(...imagesGenerated));
        setImagesArray(shuffledArray);
    }

    function flipImage(image:IWord, index:number) {
        // CHECK IF IMAGE IS SELECTED
        if (cardsChosenIds?.length === 1 && cardsChosenIds[0] === index) {
            return
        }

        // Check if 
        if (cardsChosen?.length < 2) {

            setCardsChosen(cardsChosen => cardsChosen?.concat(image))
            setCardsChosenIds(cardsChosenIds => cardsChosenIds?.concat(index))

            if (cardsChosen?.length === 1) {
                // Check if images are the same
                if (cardsChosen[0] === image) { 
                    changeWord(cardsChosen[0].spriteId);
                    setOpenCards(openCards => openCards?.concat([cardsChosen[0], image]))
                }
                setTimeout(() => {
                    setCardsChosenIds([])
                    setCardsChosen([])
                }, 700)
                
            }
        }
    }

    function isCardChosen(image:IWord, index:number) {
        return cardsChosenIds?.includes(index) || openCards?.includes(image);
    }

    function startOver() {
        createCardBoard();
        setCardsChosenIds([]);
        setCardsChosen([]);
        setOpenCards([]);
    }

    useEffect(() => {
        createCardBoard();
    }, [])

    return (
        <>
            <Main>
                <Header>
                    <div>
                        {
                            openCards.length === GAME_LENGTH.MEMORY * 2 ?
                                <ReplayContainer>
                                    <Replay onClick={startOver} />
                                </ReplayContainer>
                            :
                                <></>
                        }
                        
                        <CloseContainer>
                            <Link to={`/uebungen/thema/${topic}`}>
                                <Close />
                            </Link>
                        </CloseContainer>
                    </div>
                </Header>

                <TabletAndDesktop>
                    <MemoryContainer>
                        {imagesArray?.map((image, index) => {
                            return (
                                <MemoryCardContainer key={index} onClick={() => flipImage(image, index)}>
                                    <div>
                                        <img src={require(`../../assets/Bilder/${isCardChosen(image, index) ? image.image : BLANK_CARD}.svg`)} alt="" />
                                    </div>
                                </MemoryCardContainer>
                            )
                        })}
                    </MemoryContainer>
                </TabletAndDesktop>

                <Mobile>
                    <MemoryContainerMobile>
                        {imagesArray?.map((image, index) => {
                            return (
                                <MemoryCardContainer key={index} onClick={() => flipImage(image, index)}>
                                    <div>
                                        <img src={require(`../../assets/Bilder/${isCardChosen(image, index) ? image.image : BLANK_CARD}.svg`)} alt="" />
                                    </div>
                                </MemoryCardContainer>
                            )
                        })}
                    </MemoryContainerMobile>
                </Mobile>
            </Main>
        </>
    )
};

export default Memory;
