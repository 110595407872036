export enum AUDIO_STATUS {
  DEFAULT = 1,
  PLAYING = 2,
  ENDED = 3,
}

export enum GAME_LENGTH {
  WORD = 8,
  SENTENCE = 6,
  MEMORY = 6,
}

export enum GAME_STATUS {
  NEUTRAL = 1,
  WAITING = 3,
  WRONG = 0,
  CORRECT = 2,
}

export enum GAME_COLORS {
  SUCCESS = "#59ff62",
  ERROR = "#ff7c73",
  NEUTRAL = "none"
}

export enum GAME_TIMEOUT {
  WORD = 4000,
  SENTENCE = 4000
}
