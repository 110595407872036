import React from "react";
import { Link } from "react-router-dom";

// assets
import { ReactComponent as Close } from "../../assets/icons/Close.svg";
import { ReactComponent as Topic1 } from "../../assets/Themen/Zuhause.svg";
import { ReactComponent as Topic2 } from "../../assets/Themen/Koerper.svg";
import { ReactComponent as Topic3 } from "../../assets/Themen/Kleider.svg";
import { ReactComponent as Topic4 } from "../../assets/Themen/Essen.svg";
import { ReactComponent as Topic5 } from "../../assets/Themen/Natur.svg";
import { ReactComponent as Topic6 } from "../../assets/Themen/Bauernhof.svg";
import { ReactComponent as Topic7 } from "../../assets/Themen/Jahr.svg";
import { ReactComponent as Songs } from "../../assets/Themen/Lieder.svg";

// Styles
import { Mobile, TabletAndDesktop } from "../../app/mediaQueries";
import styled from 'styled-components';
const Main = styled.div`
  width: 100%;
  height: 100vh;
`;
const Header = styled.div`
  position: fixed;
  height: 8vh;
  width: 100%;
  z-index: 1;
  > div {
    padding: 2vh 2vh 0 2vh;
  }
`;
const CloseContainer = styled.div`
  float: right;
  width: 40px;
  svg {
    transform: scale(0.9);
    &:hover {
      transform: scale(1);
    }
  }
`;

const Topic = styled.div`
  float: left;
  width: 25%;
  height: 50vh;
  svg {
    max-width: 100%;
    max-height: 100%;
    transform: scale(.9);
    &:hover {
      transform: scale(1);
    }
  }
`;

const MobileTopic = styled.div`
  float: left;
  width: 50%;
  height: 25vh;
  svg {
    max-width: 100%;
    max-height: 100%;
    transform: scale(.9);
    &:hover {
      transform: scale(1);
    }
  }
`;

const Exercises = () => {
  return (
  	<>
      <Main>
        <Header>
          <div>
            <CloseContainer>
              <Link to={`/`}>
                <Close />
              </Link>
            </CloseContainer>
          </div>
        </Header>

        <TabletAndDesktop>
          <Topic>
            <Link to={ `/uebungen/thema/zuhause` }>
              <Topic1 />
            </Link>
          </Topic>
          <Topic>
            <Link to={ `/uebungen/thema/koerper` }>
              <Topic2 />
            </Link>
          </Topic>
          <Topic>
            <Link to={ `/uebungen/thema/kleider` }>
              <Topic3 />
            </Link>
          </Topic>
          <Topic>
            <Link to={ `/uebungen/thema/essen` }>
              <Topic4 />
            </Link>
          </Topic>
          <Topic>
            <Link to={ `/uebungen/thema/natur` }>
              <Topic5 />
            </Link>
          </Topic>
          <Topic>
            <Link to={ `/uebungen/thema/bauernhof` }>
              <Topic6 />
            </Link>
          </Topic>
          <Topic>
            <Link to={ `/uebungen/thema/jahr` }>
              <Topic7 />
            </Link>
          </Topic>
          <Topic>
            <Link to={ `/lieder` }>
              <Songs />
            </Link>
          </Topic>
        </TabletAndDesktop>

        <Mobile>
          <MobileTopic>
            <Link to={ `/uebungen/thema/zuhause` }>
              <Topic1 />
            </Link>
          </MobileTopic>
          <MobileTopic>
            <Link to={ `/uebungen/thema/koerper` }>
              <Topic2 />
            </Link>
          </MobileTopic>
          <MobileTopic>
            <Link to={ `/uebungen/thema/kleider` }>
              <Topic3 />
            </Link>
          </MobileTopic>
          <MobileTopic>
            <Link to={ `/uebungen/thema/essen` }>
              <Topic4 />
            </Link>
          </MobileTopic>
          <MobileTopic>
            <Link to={ `/uebungen/thema/natur` }>
              <Topic5 />
            </Link>
          </MobileTopic>
          <MobileTopic>
            <Link to={ `/uebungen/thema/bauernhof` }>
              <Topic6 />
            </Link>
          </MobileTopic>
          <MobileTopic>
            <Link to={ `/uebungen/thema/jahr` }>
              <Topic7 />
            </Link>
          </MobileTopic>
          <MobileTopic>
            <Link to={ `/lieder` }>
              <Songs />
            </Link>
          </MobileTopic>
        </Mobile>
        
      </Main>
	  </>
  );
};

export default Exercises;

