import React from "react";
import { Link, useParams } from "react-router-dom";

// assets
import { ReactComponent as Close } from "../../assets/icons/Close.svg";
import { ReactComponent as Words } from "../../assets/Uebungen/Worte.svg";
import { ReactComponent as Sentences } from "../../assets/Uebungen/Saetze.svg";
import { ReactComponent as WordsGame } from "../../assets/Uebungen/WorteSpiel.svg";
import { ReactComponent as SentencesGame } from "../../assets/Uebungen/SaetzeSpiel.svg";
import { ReactComponent as Memory } from "../../assets/Uebungen/Memory.svg";

// Styles
import styled from 'styled-components';
const Main = styled.div`
  width: 100%;
  height: 100vh;
`;
const Header = styled.div`
  position: fixed;
  height: 8vh;
  width: 100%;
  z-index: 1;
  > div {
    padding: 2vh 2vh 0 2vh;
  }
`;
const CloseContainer = styled.div`
  float: right;
  width: 40px;
  svg {
    transform: scale(0.9);
    &:hover {
      transform: scale(1);
    }
  }
`;

const Game = styled.div`
  float: left;
  width: 50%;
  height: 30vh;
  margin-top: 3vh;
  svg {
    max-width: 100%;
    max-height: 100%;
    &:hover {
      transform: scale(1.1);
    }
  }
`;

const Game5 = styled.div`
  float: left;
  width: 100%;
  height: 30vh;
  margin-top: 3vh;
  svg {
    margin: 0 auto;
    max-width: 50%;
    max-height: 100%;
    &:hover {
      transform: scale(1.1);
    }
  }
`;

const ExerciseSelection = () => {
  const { topic } = useParams();


  return (
  	<>
      <Main>
        <Header>
          <div>
            <CloseContainer>
              <Link to={`/uebungen`}>
                <Close />
              </Link>
            </CloseContainer>
          </div>
        </Header>

        <Game>
          <Link to={`/uebungen/thema/${topic}/worte`}>
            <Words />
          </Link>
        </Game>
        <Game>
          <Link to={`/uebungen/thema/${topic}/saetze`}>
            <Sentences />
          </Link>
        </Game>
        <Game>

          <Link to={ `/uebungen/thema/${topic}/wortespiel` }>
            <WordsGame />
          </Link>
        </Game>
        <Game>
          <Link to={`/uebungen/thema/${topic}/saetzespiel`}>
            <SentencesGame />
          </Link>
        </Game>
        <Game5>
          <Link to={`/uebungen/thema/${topic}/memory`}>
            <Memory />
          </Link>
        </Game5>
        
      </Main>
	  </>
  );
};

export default ExerciseSelection;

