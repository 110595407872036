import { useMediaQuery } from "react-responsive";

const Desktop = ({ children }: any) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const TabletAndDesktop = ({ children }: any) => {
  const isTabletAndDesktop = useMediaQuery({ minWidth: 601 });
  return isTabletAndDesktop ? children : null;
};
const TabletAndMobile = ({ children }: any) => {
  const isTablet = useMediaQuery({ maxWidth: 991 });
  return isTablet ? children : null;
};
const Tablet = ({ children }: any) => {
  const isTablet = useMediaQuery({ minWidth: 601, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }: any) => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  return isMobile ? children : null;
};

const Default = ({ children }: any) => {
  const isNotMobile = useMediaQuery({ maxWidth: 992 });
  return isNotMobile ? children : null;
};

const DesktopSmall = ({ children }: any) => {
  const isDesktop = useMediaQuery({ minWidth: 992, maxWidth: 1279 });
  return isDesktop ? children : null;
};

const DesktopMedium = ({ children }: any) => {
  const isDesktop = useMediaQuery({ minWidth: 1280, maxWidth: 1919 });
  return isDesktop ? children : null;
};

const DesktopLarge = ({ children }: any) => {
  const isDesktop = useMediaQuery({ minWidth: 1920 });
  return isDesktop ? children : null;
};

const DesktopSmalltoLarge = ({ children }: any) => {
  const isDesktop = useMediaQuery({ minWidth: 992, maxWidth: 1919 });
  return isDesktop ? children : null;
};

export {
  Default,
  Desktop,
  DesktopSmall,
  DesktopMedium,
  DesktopSmalltoLarge,
  DesktopLarge,
  TabletAndMobile,
  Tablet,
  Mobile,
  TabletAndDesktop,
};
