import React from "react";
import { Link } from "react-router-dom";

// assets
import { ReactComponent as Close } from "../../assets/icons/Close.svg";
import { ReactComponent as PHGR } from "../../assets/Phgr-logo.svg";

import styled from 'styled-components';
const Main = styled.div`
  width: 100%;
  height: 100vh;
`;
const TextContainer = styled.div`
  text-align: left;
  padding: 1rem;
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.25rem;
  }
  p{
    font-size: 1rem;
  }
  li {
    font-size: 1rem;
  }
`;
const Header = styled.div`
  position: fixed;
  height: 8vh;
  width: 100%;
  z-index: 1;
  > div {
    padding: 2vh 2vh 0 2vh;
  }
`;
const CloseContainer = styled.div`
  float: right;
  width: 40px;
  svg {
    transform: scale(0.9);
    &:hover {
      transform: scale(1);
    }
  }
`;
const LogoContainer = styled.div`
  height: 5vh;
  width: 20%;
  float: right;
  > svg {
    max-width: 100%;
    max-height: 100%;
  }
`;

const Impressum = () => {
  return (
  	<>
      <Main>
        <Header>
          <div>
            <CloseContainer>
              <Link to={`/`}>
                <Close />
              </Link>
            </CloseContainer>
          </div>
        </Header>
        <TextContainer>

<LogoContainer>
  <PHGR />
</LogoContainer>

<h1>Informationen für Betreuungs- und Bezugspersonen</h1>

<p>Mit der Lernplattform „Lemotrix“ möchten wir die Sprachförderung von Kindern mit Deutsch als
Zweitsprache (DaZ) unterstützen. Sie ermöglicht, auf spielerische Art und Weise einen elementaren
Wortschatz in den sieben Bereichen „Zuhause“, „Körper und Pflege“, „Kleider“, „Essen und Trinken“,
„Natur“, „Bauernhof“ und „rund ums Jahr“ aufzubauen.<br />
Es ist wichtig zu wissen, dass Sie als Bezugsperson eine wichtige Rolle im Begleitprozess übernehmen
und Sie als Vertrauens- und Begleitperson für das Kind der Schlüssel zum Erfolg sind. Diese
Lernplattform kann Sie bei diesem Prozess unterstützen.<br />
Kinder verknüpfen neue Erfahrungen, Sinneseindrücke, wichtige Geschehnisse usw. mit dem, was sie
bereits wissen. Wenn sie in diesem Prozess die richtigen Wörter und Wendungen erfahren, werden sie
Sprache besser lernen. Neue Wörter lernen Kinder nämlich zum Beispiel nicht etwa alphabetisch, sondern
netzartig. Ein Kind muss ein Wort zudem bis zu fünfzig Mal in einer kindgerechten Situation erfahren, bis
es das Wort gelernt hat. Das hört sich nach viel an. Wir möchten Ihnen einige Tipps geben, wie Sie dem
Kind das Lernen eines Wortes erleichtern können bzw. das fünfzigfache Hören eines Wortes ermöglichen
können. Es ist wichtig, am Sprachstand der Kinder angemessene Sprachfördersituationen zu schaffen
und an der Lebenswelt der Kinder anzuknüpfen.</p>

<h2>1. Tipp: Arbeiten Sie themenspezifisch</h2>
<p>Setzen Sie über eine Zeitdauer von einigen Wochen ein Thema fest, an dem Sie intensiv arbeiten.
Wählen Sie themenspezifische Lieder, Geschichten, Verse, Spiele und andere Aktivitäten aus, die Sie in
dieser Zeit immer wiederholen. Sie können die Lieder, Geschichten und Wortlisten von der Lernplattform
nutzen – diese stehen unten zum Download bereit. Begegnet ein Kind einem Wort in unterschiedlichen
Kontexten, hilft ihm das, die Bedeutung des Wortes zu erschliessen und das Wort in seinem Wortschatz
abzuspeichern.</p>

<h2>2. Tipp: Verbalisieren Sie</h2>
<p>Kinder können nur die Wörter lernen, die sie auch hören und erfahren. Es ist wichtig, dass Sie im Alltag
möglichst viele Momente nutzen und schaffen, um die Sprache zu gebrauchen. Sie können zum Beispiel
Ihr Handeln sprachlich begleiten wie „Nun hängen wir deine Jacke auf und die Schuhe stellen wir
hierhin.“ oder „Jetzt wasche ich mir die Hände.“. Begleiten Sie zudem auch die Handlungen der Kinder
(„Mhh, du hast einen Apfel dabei und isst einen Apfel.“) und versuchen Sie zu versprachlichen, was ein
Kind sagen möchte, wenn es noch nicht selber über die sprachlichen Mittel verfügt („Ah, ich soll dir
helfen, die Schuhe auszuziehen. Soll ich dir helfen, die Schuhe auszuziehen?“)</p>

<h2>3. Tipp: Sprechen, Sprechen, Sprechen</h2>
<p>Es tönt banal, und trotzdem ist es das A und O der Sprachförderung. Führen Sie so oft wie möglich
Dialoge mit Kindern, stellen Sie Fragen, sprechen Sie! Achten Sie dabei darauf, Kindern mit wenigen
Deutschkenntnissen Hilfestellungen zu geben. Zu Beginn können Sie einfache Fragen stellen, die das
Kind mit ja oder nein beantworten kann. Sprechen Sie über Dinge, die an der Lebenswelt der Kinder
anknüpfen und im Idealfall gegenwärtig sind. Nur so kann sich das Kind die Bedeutung erschliessen.</p>

<h2>4. Tipp: Schaffen Sie eine vertrauensvolle Atmosphäre</h2>
<p>Damit Kinder lernen können, brauchen Sie einen Ort, an dem sie sich wohlfühlen. Sie brauchen das
Gefühl von Sicherheit und sozialem Miteinander. Geben Sie einem Kind Zeit, anzukommen und zeigen
Sie ihm, dass es in Ordnung ist, genauso, wie es ist. Kinder brauchen unterschiedlich viel Zeit, um sich
an einem für sie unbekannten Ort wohlzufühlen und sich zu öffnen.</p>

<p>Wir wünschen Ihnen und den Kindern viel Erfolg, Freude und auch Spass beim Entdecken der Sprache!</p>


<hr />
Downloadbereich Lieder:
<ul>
  <li>
    <a href="/Downloads/Backe backe Kuchen.pdf" download>Backe, backe Kuchen (Partitur - PDF)</a>
  </li>
  <li>
    <a href="/Downloads/Geburtstagslied.pdf" download>Geburtstagslied (Partitur - PDF)</a>
  </li>
  <li>
    <a href="/Downloads/Hoppe hoppe Reiter.pdf" download>Hoppe, hoppe Reiter (Partitur - PDF)</a>
  </li>
  <li>
    <a href="/Downloads/Keine Angst - Partitur.pdf" download>Wir haben sicher keine Angst (Partitur - PDF)</a>
  </li>
  <li>
    <a href="/Downloads/Steht ein Baum vor der Tür.pdf" download>Steht ein Baum vor der Tür (Partitur - PDF)</a>
  </li>
  <li>
    <a href="/Downloads/Unterwegs.pdf" download>Unterwegs (Partitur - PDF)</a>
  </li>
  <li>
    <a href="/Downloads/Lieder.zip" download>Audiodateien (MP3 - Zip-Archiv)</a>
  </li>
</ul>

<hr />
Downloadbereich Wörter:
<ul>
  <li>
    <a href="/Downloads/100 Zuhause.zip" download>Zuhause - Worte, Sätze und Bilder (Zip-Archiv)</a>
  </li>
  <li>
    <a href="/Downloads/200 Koerper.zip" download>Körper und Pflege - Worte, Sätze und Bilder (Zip-Archiv)</a>
  </li>
  <li>
    <a href="/Downloads/300 Kleider.zip" download>Kleider - Worte, Sätze und Bilder (Zip-Archiv)</a>
  </li>
  <li>
    <a href="/Downloads/400 Essen.zip" download>Essen und Trinken - Worte, Sätze und Bilder (Zip-Archiv)</a>
  </li>
  <li>
    <a href="/Downloads/500 Natur.zip" download>Natur - Worte, Sätze und Bilder (Zip-Archiv)</a>
  </li>
  <li>
    <a href="/Downloads/600 Bauernhof.zip" download>Bauernhof - Worte, Sätze und Bilder (Zip-Archiv)</a>
  </li>
  <li>
    <a href="/Downloads/700 Rund ums Jahr.zip" download>Rund ums Jahr - Worte, Sätze und Bilder (Zip-Archiv)</a>
  </li>
</ul>



        </TextContainer>
      </Main>
	  </>
  );
};

export default Impressum;

