import React from "react";
import { Link, useParams } from "react-router-dom";

// assets
import { ReactComponent as Close } from "../../assets/icons/Close.svg";

// data
import { GROUPS } from "../../constants/Words";

// Styles
import styled from 'styled-components';
const Main = styled.div`
  width: 100%;
  height: 100vh;
`;
const Header = styled.div`
  position: fixed;
  height: 8vh;
  width: 100%;
  z-index: 1;
  > div {
    padding: 2vh 2vh 0 2vh;
  }
`;
const CloseContainer = styled.div`
  float: right;
  width: 40px;
  img {
    transform: scale(0.9);
    &:hover {
      transform: scale(1);
    }
  }
`;

const Group = styled.div`
  float: left;
  width: 50%;
  height: 33vh;
  img {
    max-width: 100%;
    max-height: 100%;
    &:hover {
      transform: scale(1.1);
    }
  }
`;

const WordGroupSelection = () => {
  const { topic } = useParams();
  const topicGroups = GROUPS.find(groups => groups.topic === topic);
  const groups = topicGroups ? topicGroups.groups : GROUPS[0].groups; 

  return (
  	<>
      <Main>
        <Header>
          <div>
            <CloseContainer>
              <Link to={`/uebungen/thema/${topic}`}>
                <Close />
              </Link>
            </CloseContainer>
          </div>
        </Header>

        {
          groups.map((group, i) => {
             return (
                <Group>
                  <Link to={`/uebungen/thema/${topic}/worte/gruppe/${group.name}`}>
                    <img src={require(`../../assets/Bilder/${group.image}.svg`)} alt={group.image} />
                  </Link>
                </Group>
              ) 
          })
        }        
      </Main>
	  </>
  );
};

export default WordGroupSelection;

