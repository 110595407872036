import React from "react";
import { Link } from "react-router-dom";

// Components
import { StoryNavigation } from "../../components/StoryNavigation";

// Assets
import { ReactComponent as Close } from "../../assets/icons/Close.svg";

// Styles
import styled from 'styled-components';
const Main = styled.div`
  width: 100%;
  height: 100vh;
`;
const Header = styled.div`
  position: fixed;
  height: 8vh;
  width: 100%;
  > div {
    padding: 2vh 2vh 0 2vh;
    > a {
      display: block;
      width: 80px;
      float: left;
      svg {
        transform: scale(0.9);
        &:hover {
          transform: scale(1);
        }
      }
    }
  }
`;
const CloseContainer = styled.div`
  float: right;
  width: 50px;
  svg {
    transform: scale(0.9);
    &:hover {
      transform: scale(1);
    }
  }
`;

const Story = () => {

  return (
  	<>
      <Main>
        <Header>
          <div>
            <CloseContainer>
              <Link to={`/`}>
                <Close />
              </Link>
            </CloseContainer>
          </div>
        </Header>
        
        <StoryNavigation />

      </Main>
	  </>
  );
};

export default Story;
