import { ITopicSentence, IGroups } from "../interfaces";

export const GROUPS:Array<IGroups> = [
  {
    topic: "zuhause",
    groups: [
      { name: "garten", image: "115_Garten", coloredImage: "115_Garten" },
      { name: "geburtstag", image: "142_Geburtstag", coloredImage: "142_Geburtstag" },
      { name: "buntstifte", image: "149_Die Buntstifte", coloredImage: "149_Die Buntstifte" },
    ]
  },
  {
    topic: "koerper",
    groups: [
      { name: "hand", image: "209_Die Hand", coloredImage: "209_Die Hand" },
      { name: "zahnbuerste", image: "220_Die Zahnbürste", coloredImage: "220_Die Zahnbürste" },
    ]
  },
  {
    topic: "kleider",
    groups: [
      { name: "finken", image: "306_Die Finken", coloredImage: "306_Die Finken" },
      { name: "gestreift", image: "324_gestreift", coloredImage: "324_gestreift" },
      { name: "regenschirm", image: "331_Der Regenschirm", coloredImage: "331_Der Regenschirm" },
    ]
  },
  {
    topic: "essen",
    groups: [
      { name: "banane", image: "403_Die Banane", coloredImage: "403_Die Banane" },
      { name: "tomate", image: "409_Die Tomate", coloredImage: "409_Die Tomate" },
      { name: "essen", image: "434_essen", coloredImage: "434_essen" },
      { name: "pizza", image: "425_Die Pizza", coloredImage: "425_Die Pizza" },
    ]
  },
  {
    topic: "natur",
    groups: [
      { name: "blatt", image: "503_Das Blatt", coloredImage: "503_Das Blatt" },
      { name: "schnecke", image: "514_Die Schnecke", coloredImage: "514_Die Schnecke" },
      { name: "kaefer", image: "519_Der Käfer", coloredImage: "519_Der Käfer" },
      { name: "ente", image: "526_Die Ente", coloredImage: "526_Die Ente" },
    ]
  },
  {
    topic: "bauernhof",
    groups: [
      { name: "kueken", image: "604_Das Küken", coloredImage: "604_Das Küken" },
      { name: "pferd", image: "611_Das Pferd", coloredImage: "611_Das Pferd" },
      { name: "baustelle", image: "650_Die Baustelle", coloredImage: "650_Die Baustelle" },
    ]
  },
  {
    topic: "jahr",
    groups: [
      { name: "schnee", image: "708_Der Schnee", coloredImage: "708_Der Schnee" },
      { name: "regenbogen", image: "712_Der Regenbogen", coloredImage: "712_Der Regenbogen" },
      { name: "weihnachtsbaum", image: "718_Der Weihnachtsbaum", coloredImage: "718_Der Weihnachtsbaum" },
      { name: "schneemann", image: "722_Der Schneemann", coloredImage: "722_Der Schneemann" },
    ]
  }
];

export const SENTENCES:Array<ITopicSentence> = [
  {
    name: "zuhause",
    sentences: [
      { spriteId: "sentence1", image1: "101_Mutter_Frau", image2: "102_Vater_der_Mann", sentence: "Meine Mutter ist eine Frau. Mein Vater ist ein Mann.", group: "geburtstag" },
      { spriteId: "sentence2", image1: "103_Bruder", image2: "136_Junge", sentence: "Mein Bruder ist ein Junge.", group: "geburtstag" },
      { spriteId: "sentence3", image1: "104_Schwester", image2: "137_Mädchen", sentence: "Meine Schwester ist ein Mädchen.", group: "geburtstag" },
      { spriteId: "sentence4", image1: "105_Molnar", image2: "168_Molnar", sentence: "Ich bin ein Junge und heisse Molnar.", group: "geburtstag" },
      { spriteId: "sentence5", image1: "133_Haus", image2: "109_Fenster", sentence: "Das Haus hat 4 Fenster.", group: "garten" },
      { spriteId: "sentence6", image1: "127_Bett", image2: "112_Schlafzimmer", sentence: "Das Bett ist im Schlafzimmer.", group: "garten" },
      { spriteId: "sentence7", image1: "113_Wohnzimmer", image2: "118_Sofa", sentence: "Im Wohnzimmer ist das Sofa.", group: "garten" },
      { spriteId: "sentence8", image1: "111_Badezimmer", image2: "121_Badewanne", sentence: "Im Badezimmer ist die Badewanne.", group: "garten" },
      { spriteId: "sentence9", image1: "119_Schrank", image2: "138_Kleider", sentence: "Im Schrank sind Kleider.", group: "garten" },
      { spriteId: "sentence10", image1: "139_Regal", image2: "131_Buch", sentence: "Im Regal sind Bücher.", group: "garten" },
      { spriteId: "sentence11", image1: "106_Grossmutter", image2: "132_Computer", sentence: "Die Grossmutter ist am Computer.", group: "geburtstag" },
      { spriteId: "sentence12", image1: "133_Haus", image2: "108_Tür", sentence: "Das Haus hat eine Tür.", group: "garten" },
      { spriteId: "sentence13", image1: "120_WC", image2: "111_Badezimmer", sentence: "Das WC ist im Badezimmer.", group: "garten" },
      { spriteId: "sentence14", image1: "110_Küche", image2: "124_Kühlschrank", sentence: "In der Küche ist der Kühlschrank.", group: "garten" },
      { spriteId: "sentence15", image1: "117_Tisch", image2: "116_Stuhl", sentence: "Am Tisch sind Stühle.", group: "garten" },
      { spriteId: "sentence16", image1: "115_Garten", image2: "502_Die Blume", sentence: "Im Garten sind Blumen.", group: "garten" },
      { spriteId: "sentence17", image1: "107_Grossvater", image2: "142_Geburtstag", sentence: "Der Grossvater hat Geburtstag.", group: "geburtstag" },
      { spriteId: "sentence18", image1: "105_Molnar", image2: "120_WC", sentence: "Molnar geht aufs WC.", group: "geburtstag" },
      { spriteId: "sentence19", image1: "105_Molnar", image2: "143_Hände_waschen", sentence: "Molnar wäscht sich die Hände.", group: "geburtstag" },
      { spriteId: "sentence20", image1: "105_Molnar", image2: "144_Zähne_putzen", sentence: "Molnar putzt sich die Zähne.", group: "geburtstag" },
      { spriteId: "sentence21", image1: "146_Die Schere", image2: "852_spitzig", sentence: "Die Schere ist spitzig.", group: "buntstifte" },
      { spriteId: "sentence22", image1: "147_Der Leim", image2: "167_kleben", sentence: "Der Leim klebt.", group: "buntstifte" },
      { spriteId: "sentence23", image1: "148_Das Blatt Papier", image2: "339_weiss", sentence: "Das Blatt Papier ist weiss.", group: "buntstifte" },
      { spriteId: "sentence24", image1: "149_Die Buntstifte", image2: "344_rot gelb grün", sentence: "Die Farbstifte sind rot, gelb und grün.", group: "buntstifte" },
      { spriteId: "sentence25", image1: "150_Der Bleistift", image2: "117_Tisch", sentence: "Der Bleistift ist auf dem Tisch.", group: "buntstifte" },
      { spriteId: "sentence26", image1: "151_Der Pinsel", image2: "810_weich", sentence: "Der Pinsel ist weich.", group: "buntstifte" },
      { spriteId: "sentence27", image1: "152_Die Wasserfarben", image2: "341_bunt", sentence: "Die Wasserfarben sind bunt.", group: "buntstifte" },
      { spriteId: "sentence28", image1: "153_Der Spitzer", image2: "117_Tisch", sentence: "Der Spitzer ist auf dem Tisch.", group: "buntstifte" },
      { spriteId: "sentence29", image1: "154_Der Würfel", image2: "812_eckig", sentence: "Der Würfel ist eckig.", group: "buntstifte" },
      { spriteId: "sentence30", image1: "155_Das Spiel", image2: "854_lustig", sentence: "Das Spiel ist lustig.", group: "buntstifte" },
    ]
  },
  {
    name: "koerper",
    sentences: [
      { spriteId: "sentence1", image1: "201_Der Kopf", image2: "811_rund", sentence: "Der Kopf ist rund.", group: "hand" },
      { spriteId: "sentence2", image1: "202_Der Hals", image2: "201_Der Kopf", sentence: "Der Hals ist unter dem Kopf.", group: "hand" },
      { spriteId: "sentence3", image1: "203_Der Arm", image2: "817_stark", sentence: "Der Arm ist stark.", group: "hand" },
      { spriteId: "sentence4", image1: "204_Das Bein", image2: "836_hüpfen", sentence: "Mit dem Bein kann ich hüpfen.", group: "hand" },
      { spriteId: "sentence5", image1: "205_Der Fuss", image2: "838_gehen", sentence: "Mit dem Fuss kann ich gehen.", group: "hand" },
      { spriteId: "sentence6", image1: "206_Der Bauch", image2: "436_Der Hunger", sentence: "Der Bauch hat Hunger.", group: "hand" },
      { spriteId: "sentence7", image1: "207_Der Rücken", image2: "815_lang", sentence: "Der Rücken ist lang.", group: "hand" },
      { spriteId: "sentence8", image1: "208_Der Po", image2: "825_hinten", sentence: "Der Po ist hinten.", group: "hand" },
      { spriteId: "sentence9", image1: "209_Die Hand", image2: "143_Hände_waschen", sentence: "Die Hand muss ich waschen.", group: "hand" },
      { spriteId: "sentence10", image1: "210_Der Finger", image2: "840_zählen", sentence: "Mit den Fingern kann ich zählen.", group: "hand" },
      { spriteId: "sentence11", image1: "211_Das Ohr", image2: "843_hören", sentence: "Mit den Ohren kann ich hören.", group: "hand" },
      { spriteId: "sentence12", image1: "212_Das Auge", image2: "842_sehen", sentence: "Mit den Augen kann ich sehen.", group: "zahnbuerste" },
      { spriteId: "sentence13", image1: "213_Das Gesicht", image2: "803_schön", sentence: "Das Gesicht ist schön.", group: "zahnbuerste" },
      { spriteId: "sentence14", image1: "214_Die Haare", image2: "816_kurz", sentence: "Die Haare sind kurz.", group: "zahnbuerste" },
      { spriteId: "sentence15", image1: "215_Die Zunge", image2: "820_nass", sentence: "Die Zunge ist nass.", group: "zahnbuerste" },
      { spriteId: "sentence16", image1: "216_Der Mund", image2: "801_gross", sentence: "Der Mund ist gross.", group: "zahnbuerste" },
      { spriteId: "sentence17", image1: "218_Das Nastuch", image2: "217_Die Nase", sentence: "Das Nastuch ist für die Nase.", group: "zahnbuerste" },
      { spriteId: "sentence18", image1: "219_Der Zahn", image2: "220_Die Zahnbürste", sentence: "Ich putze die Zähne mit der Zahnbürste.", group: "zahnbuerste" },
      { spriteId: "sentence19", image1: "223_Das Pflaster", image2: "812_eckig", sentence: "Das Pflaster ist eckig.", group: "zahnbuerste" },
      { spriteId: "sentence20", image1: "224_Die Seife", image2: "821_gut", sentence: "Die Seife riecht gut.", group: "zahnbuerste" },
      { spriteId: "sentence21", image1: "225_Das Handtuch", image2: "819_trocken", sentence: "Das Handtuch ist trocken.", group: "zahnbuerste" },
    ]
  },
  {
    name: "kleider",
    sentences: [
      { spriteId: "sentence1", image1: "301_Die Hose", image2: "815_lang", sentence: "Die Hose ist lang.", group: "finken" },
      { spriteId: "sentence2", image1: "302_Die Jacke", image2: "860_warm", sentence: "Die Jacke ist warm.", group: "finken" },
      { spriteId: "sentence3", image1: "303_Die Mütze", image2: "201_Der Kopf", sentence: "Die Mütze ist auf dem Kopf.", group: "finken" },
      { spriteId: "sentence4", image1: "304_Die Schuhe", image2: "855_schmutzig", sentence: "Die Schuhe sind schmutzig.", group: "finken" },
      { spriteId: "sentence5", image1: "305_Die Socken", image2: "338_schwarz", sentence: "Die Socken sind schwarz.", group: "finken" },
      { spriteId: "sentence6", image1: "306_Die Finken", image2: "341_bunt", sentence: "Die Finken sind farbig.", group: "finken" },
      { spriteId: "sentence7", image1: "307_Der Pullover", image2: "810_weich", sentence: "Der Pullover ist weich.", group: "finken" },
      { spriteId: "sentence8", image1: "308_Das T-Shirt", image2: "333_gelb", sentence: "Das T-Shirt ist gelb.", group: "finken" },
      { spriteId: "sentence9", image1: "309_Das Pyjama", image2: "127_Bett", sentence: "Das Pyjama ist im Bett.", group: "finken" },
      { spriteId: "sentence10", image1: "310_Die Brille", image2: "217_Die Nase", sentence: "Die Brille ist auf der Nase.", group: "gestreift" },
      { spriteId: "sentence11", image1: "311_Die Tasche", image2: "801_gross", sentence: "Die Tasche ist gross.", group: "gestreift" },
      { spriteId: "sentence12", image1: "312_Die Strumpfhose", image2: "321_anziehen", sentence: "Die Strumpfhose ist eng zum anziehen.", group: "gestreift" },
      { spriteId: "sentence13", image1: "313_Die Unterhose", image2: "816_kurz", sentence: "Die Unterhose ist kurz.", group: "gestreift" },
      { spriteId: "sentence14", image1: "314_Das Unterhemd", image2: "339_weiss", sentence: "Das Unterhemd ist weiss.", group: "gestreift" },
      { spriteId: "sentence15", image1: "315_Das Kleid", image2: "803_schön", sentence: "Das Kleid ist schön.", group: "gestreift" },
      { spriteId: "sentence16", image1: "316_Die Handschuhe", image2: "209_Die Hand", sentence: "Die Handschuhe sind an den Händen.", group: "gestreift" },
      { spriteId: "sentence17", image1: "317_Der Schal", image2: "202_Der Hals", sentence: "Der Schal ist am Hals.", group: "gestreift" },
      { spriteId: "sentence18", image1: "318_Der Skianzug", image2: "324_gestreift", sentence: "Der Skianzug ist gestreift.", group: "gestreift" },
      { spriteId: "sentence19", image1: "319_Der Reissverschluss", image2: "815_lang", sentence: "Der Reissverschluss ist lang.", group: "regenschirm" },
      { spriteId: "sentence20", image1: "320_Der Knopf", image2: "811_rund", sentence: "Der Knopf ist rund.", group: "regenschirm" },
      { spriteId: "sentence21", image1: "325_Der Rucksack", image2: "336_orange", sentence: "Der Rucksack ist orange.", group: "regenschirm" },
      { spriteId: "sentence22", image1: "326_Die Turnhose", image2: "332_rot", sentence: "Die Turnhose ist rot.", group: "regenschirm" },
      { spriteId: "sentence23", image1: "327_Das Badekleid", image2: "323_gepunktet", sentence: "Das Badekleid ist gepunktet.", group: "regenschirm" },
      { spriteId: "sentence24", image1: "328_Die Badehose", image2: "324_gestreift", sentence: "Die Badehose ist gestreift.", group: "regenschirm" },
      { spriteId: "sentence25", image1: "329_Die Regenjacke", image2: "343_Die Kapuze", sentence: "Die Regenjacke hat eine Kapuze.", group: "regenschirm" },
      { spriteId: "sentence26", image1: "330_Die Stiefel", image2: "807_hoch", sentence: "Die Stiefel sind hoch.", group: "regenschirm" },
      { spriteId: "sentence27", image1: "331_Der Regenschirm", image2: "820_nass", sentence: "Der Regenschirm ist nass.", group: "regenschirm" },
    ]
  },
  {
    name: "essen",
    sentences: [
      { spriteId: "sentence1", image1: "401_Der Apfel", image2: "501_Der Baum", sentence: "Der Apfel ist am Baum.", group: "banane" },
      { spriteId: "sentence2", image1: "402_Die Birne", image2: "501_Der Baum", sentence: "Die Birne ist am Baum.", group: "banane" },
      { spriteId: "sentence3", image1: "403_Die Banane", image2: "830_krumm", sentence: "Die Banane ist krumm.", group: "banane" },
      { spriteId: "sentence4", image1: "404_Die Erdbeere", image2: "438_schmeckt gut", sentence: "Die Erdbeere schmeckt gut.", group: "banane" },
      { spriteId: "sentence5", image1: "405_Die Orange", image2: "437_Die Schale", sentence: "Die Orange hat eine Schale.", group: "banane" },
      { spriteId: "sentence6", image1: "406_Die Kartoffel", image2: "504_Die Erde", sentence: "Die Kartoffel ist in der Erde.", group: "banane" },
      { spriteId: "sentence7", image1: "407_Die Karotte", image2: "336_orange", sentence: "Die Karotte ist orange.", group: "banane" },
      { spriteId: "sentence8", image1: "408_Der Salat", image2: "334_grün", sentence: "Der Salat ist grün.", group: "banane" },
      { spriteId: "sentence9", image1: "409_Die Tomate", image2: "332_rot", sentence: "Die Tomate ist rot.", group: "tomate" },
      { spriteId: "sentence10", image1: "410_Die Zwiebel", image2: "841_riechen", sentence: "Die Zwiebel riecht.", group: "tomate" },
      { spriteId: "sentence11", image1: "411_Die Wurst", image2: "801_gross", sentence: "Die Wurst ist gross.", group: "tomate" },
      { spriteId: "sentence12", image1: "412_Das Brot", image2: "337_braun", sentence: "Das Brot ist braun.", group: "tomate" },
      { spriteId: "sentence13", image1: "413_Das Brötchen", image2: "802_klein", sentence: "Das Brötchen ist klein.", group: "tomate" },
      { spriteId: "sentence14", image1: "414_Das Joghurt", image2: "404_Die Erdbeere", sentence: "Im Joghurt sind Erdbeeren.", group: "tomate" },
      { spriteId: "sentence15", image1: "415_Die Butter", image2: "810_weich", sentence: "Die Butter ist weich.", group: "tomate" },
      { spriteId: "sentence16", image1: "416_Der Saft", image2: "429_Das Glas", sentence: "Der Saft ist im Glas.", group: "tomate" },
      { spriteId: "sentence17", image1: "417_Das Wasser", image2: "820_nass", sentence: "Das Wasser ist nass.", group: "essen" },
      { spriteId: "sentence18", image1: "418_Die Chips", image2: "819_trocken", sentence: "Die Chips sind trocken.", group: "essen" },
      { spriteId: "sentence19", image1: "419_Das Salz", image2: "339_weiss", sentence: "Das Salz ist weiss.", group: "essen" },
      { spriteId: "sentence20", image1: "420_Der Zucker", image2: "440_süss", sentence: "Der Zucker ist süss.", group: "essen" },
      { spriteId: "sentence21", image1: "421_Der Tee", image2: "442_heiss", sentence: "Der Tee ist heiss.", group: "essen" },
      { spriteId: "sentence22", image1: "422_Die Konfitüre", image2: "412_Das Brot", sentence: "Die Konfitüre ist auf dem Brot.", group: "essen" },
      { spriteId: "sentence23", image1: "423_Die Schokolade", image2: "337_braun", sentence: "Die Schokolade ist braun.", group: "essen" },
      { spriteId: "sentence24", image1: "424_Das Glace", image2: "443_kalt", sentence: "Das Glace ist kalt.", group: "essen" },
      { spriteId: "sentence25", image1: "425_Die Pizza", image2: "135_Der Backofen", sentence: "Die Pizza ist im Backofen.", group: "pizza" },
      { spriteId: "sentence26", image1: "426_Die Spaghetti", image2: "815_lang", sentence: "Die Spaghetti sind lang.", group: "pizza" },
      { spriteId: "sentence27", image1: "427_Der Teller", image2: "811_rund", sentence: "Der Teller ist rund.", group: "pizza" },
      { spriteId: "sentence28", image1: "428_Die Tasse", image2: "807_hoch", sentence: "Die Tasse ist hoch.", group: "pizza" },
      { spriteId: "sentence29", image1: "429_Das Glas", image2: "857_dünn", sentence: "Das Glas ist dünn.", group: "pizza" },
      { spriteId: "sentence30", image1: "430_Der Löffel", image2: "439_Die Suppe", sentence: "Der Löffel ist in der Suppe.", group: "pizza" },
      { spriteId: "sentence31", image1: "431_Die Gabel", image2: "852_spitzig", sentence: "Die Gabel ist spitzig.", group: "pizza" },
      { spriteId: "sentence32", image1: "432_Das Messer", image2: "444_schneiden", sentence: "Das Messer schneidet gut.", group: "pizza" },
    ]
  },
  {
    name: "natur",
    sentences: [
      { spriteId: "sentence1", image1: "501_Der Baum", image2: "801_gross", sentence: "Der Baum ist gross.", group: "blatt" },
      { spriteId: "sentence2", image1: "502_Die Blume", image2: "803_schön", sentence: "Die Blume ist schön.", group: "blatt" },
      { spriteId: "sentence3", image1: "503_Das Blatt", image2: "334_grün", sentence: "Das Blatt ist grün.", group: "blatt" },
      { spriteId: "sentence4", image1: "504_Die Erde", image2: "337_braun", sentence: "Die Erde ist braun.", group: "blatt" },
      { spriteId: "sentence5", image1: "505_Der Pilz", image2: "802_klein", sentence: "Der Pilz ist klein.", group: "blatt" },
      { spriteId: "sentence6", image1: "506_Der Wald", image2: "501_Der Baum", sentence: "Im Wald sind viele Bäume.", group: "blatt" },
      { spriteId: "sentence7", image1: "507_Der Igel", image2: "532_Der Stachel", sentence: "Der Igel hat Stacheln.", group: "blatt" },
      { spriteId: "sentence8", image1: "508_Der Hase", image2: "805_schnell", sentence: "Der Hase rennt schnell.", group: "blatt" },
      { spriteId: "sentence9", image1: "509_Das Eichhörnchen", image2: "501_Der Baum", sentence: "Das Eichhörnchen ist auf dem Baum.", group: "schnecke" },
      { spriteId: "sentence10", image1: "510_Der Fuchs", image2: "506_Der Wald", sentence: "Der Fuchs wohnt im Wald.", group: "schnecke" },
      { spriteId: "sentence11", image1: "511_Der Vogel", image2: "834_fliegen", sentence: "Der Vogel fliegt.", group: "schnecke" },
      { spriteId: "sentence12", image1: "512_Die Maus", image2: "133_Haus", sentence: "Die Maus ist im Haus.", group: "schnecke" },
      { spriteId: "sentence13", image1: "513_Das Nest", image2: "811_rund", sentence: "Das Nest ist rund.", group: "schnecke" },
      { spriteId: "sentence14", image1: "514_Die Schnecke", image2: "806_langsam", sentence: "Die Schnecke ist langsam.", group: "schnecke" },
      { spriteId: "sentence15", image1: "515_Die Spinne", image2: "204_Das Bein", sentence: "Die Spinne hat Beine.", group: "schnecke" },
      { spriteId: "sentence16", image1: "516_Die Ameise", image2: "338_schwarz", sentence: "Die Ameise ist schwarz.", group: "schnecke" },
      { spriteId: "sentence17", image1: "517_Die Biene", image2: "533_Der Honig", sentence: "Die Biene macht Honig.", group: "kaefer" },
      { spriteId: "sentence18", image1: "518_Der Wurm", image2: "534_Der Boden", sentence: "Der Wurm ist am Boden.", group: "kaefer" },
      { spriteId: "sentence19", image1: "519_Der Käfer", image2: "332_rot", sentence: "Der käfer ist rot.", group: "kaefer" },
      { spriteId: "sentence20", image1: "520_Der Schmetterling", image2: "341_bunt", sentence: "Der Schmetterling ist farbig.", group: "kaefer" },
      { spriteId: "sentence21", image1: "521_Die Wiese", image2: "502_Die Blume", sentence: "Auf der Wiese sind Blumen.", group: "kaefer" },
      { spriteId: "sentence22", image1: "522_Der Fluss", image2: "815_lang", sentence: "Der Fluss ist lang.", group: "kaefer" },
      { spriteId: "sentence23", image1: "523_Der See", image2: "417_Das Wasser", sentence: "Der See hat viel Wasser.", group: "kaefer" },
      { spriteId: "sentence24", image1: "524_Der Fisch", image2: "837_schwimmen", sentence: "Der Fisch schwimmt.", group: "kaefer" },
      { spriteId: "sentence25", image1: "525_Der Frosch", image2: "836_hüpfen", sentence: "Der Frosch hüpft.", group: "ente" },
      { spriteId: "sentence26", image1: "526_Die Ente", image2: "412_Das Brot", sentence: "Die Ente isst Brot.", group: "ente" },
      { spriteId: "sentence27", image1: "527_Die Schlange", image2: "835_kriechen", sentence: "Die Schlange kriecht.", group: "ente" },
      { spriteId: "sentence28", image1: "528_Der Ast", image2: "501_Der Baum", sentence: "Der Ast ist am Baum.", group: "ente" },
      { spriteId: "sentence29", image1: "529_Der Stein", image2: "809_hart", sentence: "Der Stein ist hart.", group: "ente" },
      { spriteId: "sentence30", image1: "530_Die Nuss", image2: "535_Die Schale", sentence: "Die Nuss hat eine Schale.", group: "ente" },
      { spriteId: "sentence31", image1: "531_Der Berg", image2: "807_hoch", sentence: "Der Berg ist hoch.", group: "ente" },
      { spriteId: "sentence32", image1: "105_Molnar", image2: "506_Der Wald", sentence: "Ich spaziere im Wald.", group: "ente" },
    ]
  }, 
  {
    name: "bauernhof",
    sentences: [
      { spriteId: "sentence1", image1: "601_Die Kuh", image2: "619_Die Milch", sentence: "Die Kuh gibt Milch.", group: "kueken" },
      { spriteId: "sentence2", image1: "602_Das Huhn", image2: "615_Das Ei", sentence: "Das Huhn gibt Eier.", group: "kueken" },
      { spriteId: "sentence3", image1: "603_Der Hahn", image2: "823_laut", sentence: "Der Hahn ist laut.", group: "kueken" },
      { spriteId: "sentence4", image1: "604_Das Küken", image2: "333_gelb", sentence: "Das Küken ist gelb.", group: "kueken" },
      { spriteId: "sentence5", image1: "605_Die Ziege", image2: "614_Der Stall", sentence: "Die Ziege ist im Stall.", group: "kueken" },
      { spriteId: "sentence6", image1: "606_Das Schaf", image2: "614_Der Stall", sentence: "Das Schaf ist im Stall.", group: "kueken" },
      { spriteId: "sentence7", image1: "607_Das Schwein", image2: "614_Der Stall", sentence: "Das Schwein ist im Stall.", group: "kueken" },
      { spriteId: "sentence8", image1: "608_Der Hund", image2: "847_bellen", sentence: "Der Hund bellt.", group: "kueken" },
      { spriteId: "sentence9", image1: "609_Die Katze", image2: "848_miauen", sentence: "Die Katze miaut.", group: "kueken" },
      { spriteId: "sentence10", image1: "610_Der Esel", image2: "342_grau", sentence: "Der Esel ist grau.", group: "kueken" },
      { spriteId: "sentence11", image1: "611_Das Pferd", image2: "807_hoch", sentence: "Das Pferd ist hoch.", group: "pferd" },
      { spriteId: "sentence12", image1: "612_Das Fell", image2: "810_weich", sentence: "Das Fell ist weich.", group: "pferd" },
      { spriteId: "sentence13", image1: "613_Das Futter", image2: "821_gut", sentence: "Das Futter ist gut.", group: "pferd" },
      { spriteId: "sentence14", image1: "616_Der Traktor", image2: "806_langsam", sentence: "Der Traktor fährt langsam.", group: "pferd" },
      { spriteId: "sentence15", image1: "618_Der Käse", image2: "812_eckig", sentence: "Der käse ist eckig.", group: "pferd" },
      { spriteId: "sentence16", image1: "606_Das Schaf", image2: "621_Das Gras", sentence: "Das Schaf frisst Gras.", group: "pferd" },
      { spriteId: "sentence17", image1: "607_Das Schwein", image2: "614_Der Stall", sentence: "Das Schwein ist im Stall.", group: "pferd" },
      { spriteId: "sentence18", image1: "620_Das Bauernhaus", image2: "622_Die Tiere", sentence: "Das Bauernhaus hat viele Tiere.", group: "pferd" },
      { spriteId: "sentence19", image1: "605_Die Ziege", image2: "521_Die Wiese", sentence: "Die Ziege ist auf der Wiese.", group: "pferd" },
      { spriteId: "sentence20", image1: "650_Die Baustelle", image2: "801_gross", sentence: "Die Baustelle ist gross.", group: "baustelle" },
      { spriteId: "sentence21", image1: "651_Der Kran", image2: "807_hoch", sentence: "Der Kran ist hoch.", group: "baustelle" },
      { spriteId: "sentence22", image1: "652_Das Auto", image2: "659_Die Strasse", sentence: "Das Auto fährt auf der Strasse.", group: "baustelle" },
      { spriteId: "sentence23", image1: "653_Das Velo", image2: "659_Die Strasse", sentence: "Das Velo fährt auf der Strasse.", group: "baustelle" },
      { spriteId: "sentence24", image1: "654_Der Bus", image2: "659_Die Strasse", sentence: "Der Bus fährt auf Strasse.", group: "baustelle" },
      { spriteId: "sentence25", image1: "655_Das Motorrad", image2: "823_laut", sentence: "Das Motorrad ist laut.", group: "baustelle" },
      { spriteId: "sentence26", image1: "656_Das Flugzeug", image2: "736_Der Himmel", sentence: "Das Flugzeug fliegt am Himmel.", group: "baustelle" },
      { spriteId: "sentence27", image1: "657_Der Zug", image2: "660_Der Bahnhof", sentence: "Der Zug fährt am Bahnhof.", group: "baustelle" },
      { spriteId: "sentence28", image1: "658_Das Schiff", image2: "523_Der See", sentence: "Das Schiff fährt auf dem See.", group: "baustelle" },
      { spriteId: "sentence29", image1: "659_Die Strasse", image2: "661_warten_schauen_gehen", sentence: "Auf der Strasse: warten, schauen, hören, gehen!", group: "baustelle" },
    ]
  },
  {
    name: "jahr",
    sentences: [
      { spriteId: "sentence1", image1: "704_Der Frühling", image2: "502_Die Blume", sentence: "Im Frühling blühen die Blumen.", group: "schnee" },
      { spriteId: "sentence2", image1: "701_Der Sommer", image2: "860_warm", sentence: "Im Sommer ist es warm.", group: "schnee" },
      { spriteId: "sentence3", image1: "702_Der Herbst", image2: "503_Das Blatt", sentence: "Im Herbst fliegen die Blätter.", group: "schnee" },
      { spriteId: "sentence4", image1: "703_Der Winter", image2: "859_kalt", sentence: "Im Winter ist es kalt.", group: "schnee" },
      { spriteId: "sentence5", image1: "705_Die Sonne", image2: "832_hell", sentence: "Die Sonne ist hell.", group: "schnee" },
      { spriteId: "sentence6", image1: "706_Die Wolke", image2: "736_Der Himmel", sentence: "Die Wolke ist am Himmel.", group: "schnee" },
      { spriteId: "sentence7", image1: "707_Der Regen", image2: "820_nass", sentence: "Der Regen ist nass.", group: "schnee" },
      { spriteId: "sentence8", image1: "708_Der Schnee", image2: "339_weiss", sentence: "Der Schnee ist weiss.", group: "schnee" },
      { spriteId: "sentence9", image1: "709_Der Wind", image2: "805_schnell", sentence: "Der Wind ist schnell.", group: "regenbogen" },
      { spriteId: "sentence10", image1: "710_Der Mond", image2: "739_Die Nacht", sentence: "Der Mond ist in der Nacht da.", group: "regenbogen" },
      { spriteId: "sentence11", image1: "711_Der Nebel", image2: "531_Der Berg", sentence: "Der Nebel ist am Berg.", group: "regenbogen" },
      { spriteId: "sentence12", image1: "712_Der Regenbogen", image2: "341_bunt", sentence: "Der Regenbogen ist farbig.", group: "regenbogen" },
      { spriteId: "sentence13", image1: "713_Das Gewitter", image2: "823_laut", sentence: "Das Gewitter ist laut.", group: "regenbogen" },
      { spriteId: "sentence14", image1: "715_Der Osterhase", image2: "836_hüpfen", sentence: "Der Osterhase hüpft.", group: "regenbogen" },
      { spriteId: "sentence15", image1: "716_Das Osterei", image2: "115_Garten", sentence: "Das Osterei liegt im Garten.", group: "regenbogen" },
      { spriteId: "sentence16", image1: "717_Die Weihnachten", image2: "703_Der Winter", sentence: "Weihnachten ist im Winter.", group: "regenbogen" },
      { spriteId: "sentence17", image1: "718_Der Weihnachtsbaum", image2: "801_gross", sentence: "Der Weihnachtsbaum ist gross.", group: "weihnachtsbaum" },
      { spriteId: "sentence18", image1: "719_Das Geschenk", image2: "803_schön", sentence: "Das Geschenk ist schön.", group: "weihnachtsbaum" },
      { spriteId: "sentence19", image1: "721_Der Engel", image2: "853_lieb", sentence: "Der Engel ist lieb.", group: "weihnachtsbaum" },
      { spriteId: "sentence20", image1: "720_Der Samiklaus", image2: "858_dick", sentence: "Der Samiklaus ist dick.", group: "weihnachtsbaum" },
      { spriteId: "sentence21", image1: "722_Der Schneemann", image2: "854_lustig", sentence: "Der Schneemann ist lustig.", group: "schneemann" },
      { spriteId: "sentence22", image1: "723_Der Schneeball", image2: "811_rund", sentence: "Der Schneeball ist rund.", group: "weihnachtsbaum" },
      { spriteId: "sentence23", image1: "724_Der Schlitten", image2: "805_schnell", sentence: "Der Schlitten fährt schnell.", group: "weihnachtsbaum" },
      { spriteId: "sentence24", image1: "725_Die Schaufel", image2: "812_eckig", sentence: "Die Schaufel ist eckig.", group: "weihnachtsbaum" },
      { spriteId: "sentence25", image1: "726_Die Ski", image2: "714_Die Schneeflocke", sentence: "Die Ski sind im Schnee.", group: "schneemann" },
      { spriteId: "sentence26", image1: "727_Die Schlittschuhe", image2: "737_Das Eis", sentence: "Die Schlittschuhe sind auf dem Eis.", group: "schneemann" },
      { spriteId: "sentence27", image1: "728_Der Stern", image2: "736_Der Himmel", sentence: "Der Stern ist am Himmel.", group: "schneemann" },
      { spriteId: "sentence28", image1: "729_Die Kerze", image2: "718_Der Weihnachtsbaum", sentence: "Die Kerze ist am Weihnachtsbaum.", group: "weihnachtsbaum" },
      { spriteId: "sentence29", image1: "730_Der Ball", image2: "811_rund", sentence: "Der Ball ist rund.", group: "schneemann" },
      { spriteId: "sentence30", image1: "731_Der Sandkasten", image2: "819_trocken", sentence: "Der Sandkasten ist trocken.", group: "schneemann" },
      { spriteId: "sentence31", image1: "738_Der Tag", image2: "832_hell", sentence: "Am Tag ist es hell.", group: "schneemann" },
      { spriteId: "sentence32", image1: "739_Die Nacht", image2: "833_dunkel", sentence: "In der Nacht ist es dunkel.", group: "schneemann" },
    ]
  }
];
