import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from './views/Landing';
import Story from './views/Story';
import Exercises from './views/Exercises';
import ExerciseSelection from './views/Exercises/ExerciseSelection';
import WordGroupSelection from './views/Exercises/WordGroupSelection';
import SentenceGroupSelection from './views/Exercises/SentenceGroupSelection';
import Words from './views/Exercises/Words';
import WordsGame from './views/Exercises/WordsGame';
import Sentences from './views/Exercises/Sentences';
import SentencesGame from './views/Exercises/SentencesGame';
import Memory from './views/Exercises/Memory';
import Songs from './views/Pages/Songs';
import Impressum from './views/Pages/Impressum';
import NotFound from './views/NotFound';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <BrowserRouter>
          <Routes>
            <Route path={"/"} element={<Landing />} />
            <Route path={"/geschichte/:story"} element={<Story />} />
            <Route path={"/uebungen"} element={<Exercises />} />
            <Route path={"/uebungen/thema/:topic"} element={<ExerciseSelection />} />
            <Route path={"/uebungen/thema/:topic/worte"} element={<WordGroupSelection />} />
            <Route path={"/uebungen/thema/:topic/worte/gruppe/:group"} element={<Words />} />
            <Route path={"/uebungen/thema/:topic/wortespiel"} element={<WordsGame />} />
            <Route path={"/uebungen/thema/:topic/saetze"} element={<SentenceGroupSelection />} />
            <Route path={"/uebungen/thema/:topic/saetze/gruppe/:group"} element={<Sentences />} />
            <Route path={"/uebungen/thema/:topic/saetzespiel"} element={<SentencesGame />} />
            <Route path={"/uebungen/thema/:topic/memory"} element={<Memory />} />
            <Route path={"/impressum"} element={<Impressum />} />
            <Route path={"/lieder"} element={<Songs />} />
            <Route element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </header>
    </div>
  );
}

export default App;
