import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useSound from 'use-sound';

// assets
import { ReactComponent as Close } from "../../assets/icons/Close.svg";
import { ReactComponent as Song1 } from "../../assets/Geschichte/Garten_6_1.svg";
import { ReactComponent as Song2 } from "../../assets/Geschichte/Geburtstagsfest_2.svg";
import { ReactComponent as Song3 } from "../../assets/Geschichte/Garten_5.svg";
import { ReactComponent as Song4 } from "../../assets/Geschichte/Garten_3.svg";
import { ReactComponent as Song5 } from "../../assets/Geschichte/Geburtstagsfest_7.svg";
import { ReactComponent as Song6 } from "../../assets/Geschichte/Unterwegs_1_2.svg";
import { ReactComponent as Play } from "../../assets/icons/Play.svg";
import { ReactComponent as Pause } from "../../assets/icons/Pause.svg";
import { ReactComponent as Replay } from "../../assets/icons/Replay.svg";

// Data
import { SONGS } from "../../constants/Songs";

import { Mobile, TabletAndDesktop } from "../../app/mediaQueries";
import styled from 'styled-components';
const Main = styled.div`
  width: 100%;
  height: 100vh;
`;
const Header = styled.div`
  position: fixed;
  height: 8vh;
  width: 100%;
  z-index: 11;
  > div {
    padding: 2vh 2vh 0 2vh;
  }
`;
const CloseContainer = styled.div`
  float: right;
  width: 40px;
  svg {
    transform: scale(0.9);
    &:hover {
      transform: scale(1);
    }
  }
`;

const SongContainer = styled.div`
  float: left;
  width: 33%;
  height: 50vh;
  svg {
    cursor: pointer;
    max-width: 100%;
    max-height: 100%;
  }
`;

const MobileSongContainer = styled.div`
  float: left;
  width: 50%;
  height: 33vh;
  svg {
    max-width: 100%;
    max-height: 100%;
    transform: scale(1);
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  .pause {
    z-index: 10;
    cursor: pointer;
    position: absolute;
    opacity: 0;
    scale: 0.25;
    &:hover {
      opacity: 0.5;
    }
  }

  .replay {
    z-index: 10;
    cursor: pointer;
    position: absolute;
    opacity: 0.4;
    scale: 0.25;
    &:hover {
      opacity: 0.6
    }
  }

  .play {
    z-index: 10;
    cursor: pointer;
    position: absolute;
    opacity: 0.4;
    scale: 0.25;
    &:hover {
      opacity: 0.6
    }
  }
`;


const useAudio = (url: string) => {
  const [playing, setPlaying] = useState(false);
  const [ended, setEnded] = useState(false);
  const [play, { pause, stop, sound }] = useSound(url, {
    onend: function() {
      setPlaying(false);
      setEnded(true);
    }
  });

  const reset = () => {
    stop();
    setPlaying(false);
    setEnded(false);
  }

  const toggle = () => {
    setPlaying(!playing);
  }

  useEffect(() => {
    toggle();
      return () => {
        stop();
      };
    },
    [play]
  );

  useEffect(() => {
      if (playing) 
      {
        play();
      } 
      else 
      {
        pause();
      }
    },
    [playing, ended]
  );

  return [playing, toggle, ended, reset] as const;
};

const Songs = () => {
  const kuchen = SONGS.find(songs => songs.name === "Kuchen");
  const kuchenUrl = kuchen ? kuchen.url : "";
  const [kuchenPlaying, toggleKuchen, kuchenEnded, resetKuchen] = useAudio(kuchenUrl);

  const geburtstag = SONGS.find(songs => songs.name === "Geburtstag");
  const geburtstagsUrl = geburtstag ? geburtstag.url : "";
  const [geburtstagPlaying, toggleGeburtstag, geburtstagEnded, resetGeburtstag] = useAudio(geburtstagsUrl);

  const reiter = SONGS.find(songs => songs.name === "Reiter");
  const reiterUrl = reiter ? reiter.url : "";
  const [reiterPlaying, toggleReiter, reiterEnded, resetReiter] = useAudio(reiterUrl);

  const angst = SONGS.find(songs => songs.name === "Angst");
  const angstUrl = angst ? angst.url : "";
  const [angstPlaying, toggleAngst, angstEnded, resetAngst] = useAudio(angstUrl);

  const baum = SONGS.find(songs => songs.name === "Baum");
  const baumUrl = baum ? baum.url : "";
  const [baumPlaying, toggleBaum, baumEnded, resetBaum] = useAudio(baumUrl);

  const unterwegs = SONGS.find(songs => songs.name === "Unterwegs");
  const unterwegsUrl = unterwegs ? unterwegs.url : "";
  const [unterwegsPlaying, toggleUnterwegs, unterwegsEnded, resetUnterwegs] = useAudio(unterwegsUrl);

  const stopAll = (running: string) =>
  {
    if (running != "Kuchen" && kuchenPlaying)
    {
      resetKuchen();
    }
    if (running != "Geburtstag" && geburtstagPlaying)
    {
      resetGeburtstag();
    }
    if(running != "Reiter" && reiterPlaying)
    {
      resetReiter();
    }
    if(running != "Angst" && angstPlaying)
    {
      resetAngst();
    }
    if(running != "Baum" && angstPlaying)
    {
      resetBaum();
    }
    if(running != "Unterwegs" && unterwegsPlaying)
    {
      resetUnterwegs();
    }
  }

  const playKuchen = () => {
    stopAll("Kuchen");
    toggleKuchen();
  }

  const playGeburtstag = () => {
    stopAll("Geburtstag");
    toggleGeburtstag();
  }

  const playReiter = () => {
    stopAll("Reiter");
    toggleReiter();
  }

  const playAngst = () => {
    stopAll("Angst");
    toggleAngst();
  }

  const playBaum = () => {
    stopAll("Baum");
    toggleBaum();
  }

  const playUnterwegs = () => {
    stopAll("Unterwegs");
    toggleUnterwegs();
  }

  return (
  	<>
      <Main>
        <Header>
          <div>
            <CloseContainer>
              <Link to={`/uebungen`}>
                <Close />
              </Link>
            </CloseContainer>
          </div>
        </Header>
        <TabletAndDesktop>
          <SongContainer>
            <ImageContainer onClick={playKuchen}>
              {
                kuchenPlaying ?
                  <Pause className="pause" />
                :
                kuchenEnded ?
                  <Replay className="replay" />
                :
                  <Play className="play" /> 
              }
              <Song1 />
            </ImageContainer>
          </SongContainer>
          <SongContainer>
            <ImageContainer onClick={playGeburtstag}>
              {
                geburtstagPlaying ?
                  <Pause className="pause" />
                :
                geburtstagEnded ?
                  <Replay className="replay" />
                :
                  <Play className="play" /> 
              }
              <Song2 />
            </ImageContainer>
          </SongContainer>
          <SongContainer>
            <ImageContainer onClick={playReiter}>
              {
                reiterPlaying ?
                  <Pause className="pause" />
                :
                reiterEnded ?
                  <Replay className="replay" />
                :
                  <Play className="play" /> 
              }
              <Song3 />
            </ImageContainer>
          </SongContainer>
          <SongContainer>
            <ImageContainer onClick={playAngst}>
              {
                angstPlaying ?
                  <Pause className="pause" />
                :
                angstEnded ?
                  <Replay className="replay" />
                :
                  <Play className="play" /> 
              }
              <Song4 />
            </ImageContainer>
          </SongContainer>
          <SongContainer>
            <ImageContainer onClick={playBaum}>
              {
                baumPlaying ?
                  <Pause className="pause" />
                :
                baumEnded ?
                  <Replay className="replay" />
                :
                  <Play className="play" /> 
              }
              <Song5 />
            </ImageContainer>
          </SongContainer>
          <SongContainer>
            <ImageContainer onClick={playUnterwegs}>
              {
                unterwegsPlaying ?
                  <Pause className="pause" />
                :
                unterwegsEnded ?
                  <Replay className="replay" />
                :
                  <Play className="play" /> 
              }
              <Song6 />
            </ImageContainer>
          </SongContainer>
        </TabletAndDesktop>

        <Mobile>
          <MobileSongContainer>
            <ImageContainer onClick={playKuchen}>
              {
                kuchenPlaying ?
                  <Pause className="pause" />
                :
                kuchenEnded ?
                  <Replay className="replay" />
                :
                  <Play className="play" /> 
              }
              <Song1 />
            </ImageContainer>
          </MobileSongContainer>
          <MobileSongContainer>
            <ImageContainer onClick={playGeburtstag}>
              {
                geburtstagPlaying ?
                  <Pause className="pause" />
                :
                geburtstagEnded ?
                  <Replay className="replay" />
                :
                  <Play className="play" /> 
              }
              <Song2 />
            </ImageContainer>
          </MobileSongContainer>
          <MobileSongContainer>
            <ImageContainer onClick={playReiter}>
              {
                reiterPlaying ?
                  <Pause className="pause" />
                :
                reiterEnded ?
                  <Replay className="replay" />
                :
                  <Play className="play" /> 
              }
              <Song3 />
            </ImageContainer>

          </MobileSongContainer>
          <MobileSongContainer>
            <ImageContainer onClick={playAngst}>
              {
                angstPlaying ?
                  <Pause className="pause" />
                :
                angstEnded ?
                  <Replay className="replay" />
                :
                  <Play className="play" /> 
              }
              <Song4 />
            </ImageContainer>
          </MobileSongContainer>
          <MobileSongContainer>
            <ImageContainer onClick={playBaum}>
              {
                baumPlaying ?
                  <Pause className="pause" />
                :
                baumEnded ?
                  <Replay className="replay" />
                :
                  <Play className="play" /> 
              }
              <Song5 />
            </ImageContainer>
          </MobileSongContainer>
          <MobileSongContainer>
            <ImageContainer onClick={playUnterwegs}>
              {
                unterwegsPlaying ?
                  <Pause className="pause" />
                :
                unterwegsEnded ?
                  <Replay className="replay" />
                :
                  <Play className="play" /> 
              }
              <Song6 />
            </ImageContainer>
          </MobileSongContainer>
        </Mobile>
      </Main>
	  </>
  );
};

export default Songs;

