import { IStory } from "../interfaces";

import molnar1 from "../assets/Geschichte/Molnar/chapter1.mp3";
import molnar2 from "../assets/Geschichte/Molnar/chapter2.mp3";
import molnar3 from "../assets/Geschichte/Molnar/chapter3.mp3";
import molnar4 from "../assets/Geschichte/Molnar/chapter4.mp3";
import molnar5 from "../assets/Geschichte/Molnar/chapter5.mp3";
import tina1 from "../assets/Geschichte/Tina/chapter1.mp3";
import tina2 from "../assets/Geschichte/Tina/chapter2.mp3";
import tina3 from "../assets/Geschichte/Tina/chapter3.mp3";
import tina4 from "../assets/Geschichte/Tina/chapter4.mp3";
import eduardo1 from "../assets/Geschichte/Eduardo/chapter1.mp3";
import eduardo2 from "../assets/Geschichte/Eduardo/chapter2.mp3";
import eduardo3 from "../assets/Geschichte/Eduardo/chapter3.mp3";
import eduardo4 from "../assets/Geschichte/Eduardo/chapter4.mp3";
import eduardo5 from "../assets/Geschichte/Eduardo/chapter5.mp3";
import eduardo6 from "../assets/Geschichte/Eduardo/chapter6.mp3";
import pleta1 from "../assets/Geschichte/Pleta/chapter1.mp3";
import pleta2 from "../assets/Geschichte/Pleta/chapter2.mp3";
import pleta3 from "../assets/Geschichte/Pleta/chapter3.mp3";
import pleta4 from "../assets/Geschichte/Pleta/chapter4.mp3";
import pleta5 from "../assets/Geschichte/Pleta/chapter5.mp3";
import pleta6 from "../assets/Geschichte/Pleta/chapter6.mp3";
import fest1 from "../assets/Geschichte/Fest/chapter1.mp3";
import fest2 from "../assets/Geschichte/Fest/chapter2.mp3";
import fest3 from "../assets/Geschichte/Fest/chapter3.mp3";
import fest4 from "../assets/Geschichte/Fest/chapter4.mp3";
import fest5 from "../assets/Geschichte/Fest/chapter5.mp3";
import fest6 from "../assets/Geschichte/Fest/chapter6.mp3";
import fest7 from "../assets/Geschichte/Fest/chapter7.mp3";
import fest8 from "../assets/Geschichte/Fest/chapter8.mp3";
import fest9 from "../assets/Geschichte/Fest/chapter9.mp3";

export const STORY: Array<IStory> = [
	{
		name: "molnar",
		nextImage: "2_Tina",
		nextUrl: "/geschichte/tina",
		chapters: [
			{ url: molnar1, image: "Zuhause_1", images: [ 
				{ showAfter: 2, image: "Zuhause_1_2", position: "top-right" }, 
				{ showAfter: 14.5, image: "1_Finger", position: "top-right" }, 
				{ showAfter: 16.5, image: "2_Finger", position: "top-right" }, 
				{ showAfter: 18.5, image: "3_Finger", position: "top-right" }, 
				{ showAfter: 19.5, image: "4_Finger", position: "top-right" }, 
				{ showAfter: 21.5, image: "5_Finger", position: "top-right" },
				{ showAfter: 29, image: "101_Mutter_Frau", position: "top-right" },
				{ showAfter: 32, image: "102_Vater_der_Mann", position: "top-right" },
				{ showAfter: 34.5, image: "104_Schwester", position: "top-right" },
				{ showAfter: 37, image: "103_Bruder", position: "top-right" },
				{ showAfter: 40, image: "Zuhause_1_2", position: "top-right" },
				{ showAfter: 42, image: "Zuhause_1_3", position: "top-right" }, 
				{ showAfter: 49, image: "Zuhause_1_4", position: "top-right" }, 
				] },
			{ url: molnar2, image: "Zuhause_2", images: [
				{ showAfter: 14.5, image: "1_Finger", position: "top-right" }, 
				{ showAfter: 16.5, image: "2_Finger", position: "top-right" }, 
				{ showAfter: 18.5, image: "3_Finger", position: "top-right" }, 
				{ showAfter: 19.5, image: "4_Finger", position: "top-right" }, 
				{ showAfter: 21.5, image: "5_Finger", position: "top-right" },
				{ showAfter: 23.5, image: "6_Finger", position: "top-right" }, 
				{ showAfter: 25, image: "7_Finger", position: "top-right" }, 
				{ showAfter: 28.5, image: "Rezept1", position: "top-right" },
				{ showAfter: 31, image: "Rezept2", position: "top-right" },
				{ showAfter: 32.5, image: "Rezept3", position: "top-right" },
				{ showAfter: 34, image: "Rezept4", position: "top-right" },
				{ showAfter: 36, image: "Rezept5", position: "top-right" },
				{ showAfter: 38, image: "Rezept6", position: "top-right" },
				{ showAfter: 40.5, image: "Rezept7", position: "top-right" },
				] },
			{ url: molnar3, image: "Zuhause_3", images: [
				{ showAfter: 7, image: "Zuhause_3_1", position: "top-right" },
				{ showAfter: 14.5, image: "Zuhause_3_2", position: "top-right" }, 
				] },
			{ url: molnar4, image: "Zuhause_4", images: [
				{ showAfter: 5, image: "Zuhause_4_5", position: "top-right" }, 
				{ showAfter: 9, image: "Zuhause_4_3", position: "top-right" },
				{ showAfter: 16, image: "Zuhause_4_2", position: "top-right" },
				{ showAfter: 36, image: "Zuhause_4_3", position: "top-right" },
				{ showAfter: 43, image: "Zuhause_4_5", position: "top-right" }, 
				{ showAfter: 55, image: "Zuhause_4_4", position: "top-right" }, 
				] },
			{ url: molnar5, image: "Zuhause_5", images: [] },
		]
	},
	{
		name: "tina",
		nextImage: "3_Eduardo",
		nextUrl: "/geschichte/eduardo",
		chapters: [
			{ url: tina1, image: "Unterwegs_1", images: [
				{ showAfter: 19, image: "Unterwegs_1_1", position: "top-right" },
				{ showAfter: 25.5, image: "Unterwegs_1_2", position: "top-right" },
				{ showAfter: 36, image: "Unterwegs_1_3", position: "top-right" },
				{ showAfter: 49, image: "Unterwegs_1_4", position: "top-right" },
				{ showAfter: 57, image: "Unterwegs_1_5", position: "top-right" },
				{ showAfter: 61, image: "Unterwegs_1_6", position: "top-right" },
				{ showAfter: 67, image: "Unterwegs_1_1", position: "top-right" },
				{ showAfter: 73, image: "Unterwegs_1_2", position: "top-right" },
			]},
			{ url: tina2, image: "Unterwegs_2", images: [
				{ showAfter: 20, image: "Unterwegs_2_1", position: "top-right" },
			]},
			{ url: tina3, image: "Unterwegs_3", images: [
				{ showAfter: 20, image: "Unterwegs_1_1", position: "top-right" },
				{ showAfter: 28.5, image: "Unterwegs_1_2", position: "top-right" },
				{ showAfter: 50, image: "Baustelle_6_1", position: "top-right" },
			]},
			{ url: tina4, image: "Unterwegs_4", images: [
				{ showAfter: 0.5, image: "Unterwegs_4_1", position: "top-right" },
				{ showAfter: 21, image: "Zuhause_1_4", position: "top-right" },
				{ showAfter: 34, image: "Unterwegs_1_1", position: "top-right" },
			]},
		]
	},
	{
		name: "eduardo",
		nextImage: "4_Pleta",
		nextUrl: "/geschichte/pleta",
		chapters: [
			{ url: eduardo1, image: "Baustelle_1", images: []},
			{ url: eduardo2, image: "Baustelle_2", images: [
				{ showAfter: 4, image: "Baustelle_2_1", position: "top-right-small" },
				{ showAfter: 59, image: "Baustelle_2_2", position: "top-right-small" },
			]},
			{ url: eduardo3, image: "Baustelle_3", images: [
				{ showAfter: 1, image: "Baustelle_3_1", position: "top-right" },
			]},
			{ url: eduardo4, image: "Baustelle_4", images: [
				{ showAfter: 1, image: "Baustelle_3_1", position: "top-right" },
			]},
			{ url: eduardo5, image: "Baustelle_5", images: []},
			{ url: eduardo6, image: "Baustelle_6", images: [
				{ showAfter: 3, image: "Baustelle_6_1", position: "top-right-small" },
			]},
		]
	},
	{
		name: "pleta",
		nextImage: "5_Kuchen",
		nextUrl: "/geschichte/geburtstagsfest",
		chapters: [
			{ url: pleta1, image: "Garten_1", images: []},
			{ url: pleta2, image: "Garten_2", images: [
				{ showAfter: 7, image: "Garten_2_1", position: "top-left" },
				{ showAfter: 18, image: "Garten_2_2", position: "top-left" },
			]},
			{ url: pleta3, image: "Garten_3", images: []},
			{ url: pleta4, image: "Garten_4", images: [
				{ showAfter: 22, image: "Garten_4_1", position: "top-right" },
			]},
			{ url: pleta5, image: "Garten_5", images: []},
			{ url: pleta6, image: "Garten_6", images: [
				{ showAfter: 7, image: "Garten_6_1", position: "top-right" },
			]},
		]
	},
	{
		name: "geburtstagsfest",
		nextImage: "Spiele",
		nextUrl: "/uebungen",
		chapters: [
			{ url: fest1, image: "Geburtstagsfest_1", images: []},
			{ url: fest2, image: "Geburtstagsfest_2", images: []},
			{ url: fest3, image: "Geburtstagsfest_3", images: []},
			{ url: fest4, image: "Geburtstagsfest_4", images: []},
			{ url: fest5, image: "Geburtstagsfest_5", images: []},
			{ url: fest6, image: "Geburtstagsfest_6", images: []},
			{ url: fest7, image: "Geburtstagsfest_7", images: []},
			{ url: fest8, image: "Geburtstagsfest_8", images: []},
			{ url: fest9, image: "Geburtstagsfest_9", images: []},
		]
	}
];