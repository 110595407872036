import { ISprite } from "../interfaces";

import zuhause from "../assets/Uebungen/zuhause/sentences.mp3";
import koerper from "../assets/Uebungen/koerper/sentences.mp3";
import kleider from "../assets/Uebungen/kleider/sentences.mp3";
import essen from "../assets/Uebungen/essen/sentences.mp3";
import natur from "../assets/Uebungen/natur/sentences.mp3";
import bauernhof from "../assets/Uebungen/bauernhof/sentences.mp3";
import jahr from "../assets/Uebungen/jahr/sentences.mp3";

export const SENTENCESAUDIO: Array<ISprite> = [
	{
		name: "zuhause",
		url: zuhause,
		sprite: {
	      sentence1: [0, 15000],
	      sentence2: [17000, 6000],
	      sentence3: [24500, 7000],
	      sentence4: [33000, 9000],
	      sentence5: [43800, 4800],
	      sentence6: [51500, 5500],
	      sentence7: [59000, 5000],
	      sentence8: [67000, 5500],
	      sentence9: [75500, 6500],
	      sentence10: [83500, 6500],
	      sentence11: [91000, 5000],
	      sentence12: [100000, 6000],
	      sentence13: [107000, 6000],
	      sentence14: [114000, 7000],
	      sentence15: [124000, 6000],
	      sentence16: [131000, 5000],
	      sentence17: [137000, 6000],
	      sentence18: [144500, 5200],
	      sentence19: [157500, 6500],
	      sentence20: [165000, 6000],
	      sentence21: [172700, 4000],
	      sentence22: [177000, 2700],
	      sentence23: [180000, 3000],
	      sentence24: [184000, 5000],
	      sentence25: [190000, 3300],
	      sentence26: [193800, 3000],
	      sentence27: [197000, 3000],
	      sentence28: [200000, 4000],
	      sentence29: [204000, 3000],
	      sentence30: [207500, 3500],
	    }
	},
	{
		name: "koerper",
		url: koerper,
		sprite: {
			sentence1: [0, 2900],
			sentence2: [4000, 3200],
			sentence3: [8000, 2800],
			sentence4: [11500, 2400],
			sentence5: [14500, 2000],
			sentence6: [17000, 2000],
			sentence7: [19500, 3500],
			sentence8: [23000, 2200],
			sentence9: [26000, 2000],
			sentence10: [28800, 3400],
			sentence11: [32500, 3500],
			sentence12: [36000, 3000],
			sentence13: [39500, 2600], 
			sentence14: [43000, 2000],
			sentence15: [45500, 2500],
			sentence16: [49000, 3000],
			sentence17: [52000, 3500],
			sentence18: [55500, 3800],
			sentence19: [60200, 3000],
			sentence20: [64000, 2000],
			sentence21: [66000, 3800],
		}
	},
	{
		name: "kleider",
		url: kleider,
		sprite: {
			sentence1: [2200, 1800], 
			sentence2: [4500, 2000],
			sentence3: [7000, 3200], 
			sentence4: [10600, 2600], 
			sentence5: [13500, 2400], 
			sentence6: [16500, 2500],
			sentence7: [19500, 2600], 
			sentence8: [22800, 2600],
			sentence9: [26300, 3200],
			sentence10: [30200, 2500],
			sentence11: [33500, 2500],
			sentence12: [36000, 4200],
			sentence13: [41000, 3000],
			sentence14: [44000, 2500],
			sentence15: [47800, 2000],
			sentence16: [50500, 3500],
			sentence17: [54500, 2600],
			sentence18: [57900, 3500],
			sentence19: [62000, 3000],
			sentence20: [65500, 3500],
			sentence21: [69000, 3000],
			sentence22: [72500, 2500],
			sentence23: [75500, 3500],
			sentence24: [88200, 3200],
			sentence25: [91500, 3900],
			sentence26: [97400, 2200],
			sentence27: [99800, 5000],
		}
	},
	{
		name: "essen",
		url: essen,
		sprite: {
			sentence1: [500, 2700], 
			sentence2: [4000, 3000], 
			sentence3: [7500, 2600], 
			sentence4: [11000, 4000], 
			sentence5: [20000, 3400], 
			sentence6: [24000, 3000], 
			sentence7: [28000, 3000], 
			sentence8: [31000, 2400], 
			sentence9: [34000, 3000], 
			sentence10: [37200, 2300], 
			sentence11: [39500, 3500], 
			sentence12: [44200, 2500], 
			sentence13: [47000, 2500], 
			sentence14: [49500, 3800], 
			sentence15: [54000, 2300], 
			sentence16: [57000, 3000],
			sentence17: [66000, 2400],
			sentence18: [69500, 3000], 
			sentence19: [73000, 3000], 
			sentence20: [76000, 3000], 
			sentence21: [79500, 2500], 
			sentence22: [82000, 3200], 
			sentence23: [85500, 3100], 
			sentence24: [89000, 3000],
			sentence25: [92300, 3200],
			sentence26: [96000, 3000],
			sentence27: [99800, 3000],
			sentence28: [103500, 2800],
			sentence29: [106500, 2500],
			sentence30: [109500, 2800],
			sentence31: [112700, 3000],
			sentence32: [116700, 3400],
		}
	},
	{
		name: "natur",
		url: natur,
		sprite: {
			sentence1: [0, 3000], 
			sentence2: [3500, 2000], 
			sentence3: [6000, 2400], 
			sentence4: [9000, 3200], 
			sentence5: [13000, 2300], 
			sentence6: [16000, 3200], 
			sentence7: [24500, 2900], 
			sentence8: [28500, 2500], 
			sentence9: [32000, 4000], 
			sentence10: [37000, 2300], 
			sentence11: [39800, 3500], 
			sentence12: [43000, 3000], 
			sentence13: [47000, 3000], 
			sentence14: [50800, 3200], 
			sentence15: [55000, 2000], 
			sentence16: [58000, 3000], 
			sentence17: [61500, 3100], 
			sentence18: [66000, 2000], 
			sentence19: [69000, 2600], 
			sentence20: [72000, 3000], 
			sentence21: [76000, 3000], 
			sentence22: [79000, 3000], 
			sentence23: [82000, 4000], 
			sentence24: [86500, 3500], 
			sentence25: [90500, 3500], 
			sentence26: [94500, 2500], 
			sentence27: [98000, 2300], 
			sentence28: [108000, 4000], 
			sentence29: [113000, 2200], 
			sentence30: [116000, 2200], 
			sentence31: [119000, 2200], 
			sentence32: [122000, 3000], 
		}		
	},
	{
		name: "bauernhof",
		url: bauernhof,
		sprite: {
			sentence1: [0, 3000], 
			sentence2: [3000, 3000],
			sentence3: [7000, 2200],
			sentence4: [9500, 3500],
			sentence5: [13000, 3000],
			sentence6: [16000, 4000],
			sentence7: [20000, 4000],
			sentence8: [24500, 2000],
			sentence9: [27000, 3000],
			sentence10: [30000, 2700],
			sentence11: [33000, 3000],
			sentence12: [36500, 2000],
			sentence13: [39000, 2500],
			sentence14: [42000, 3000],
			sentence15: [46000, 3000],
			sentence16: [50000, 4000],
			sentence17: [54500, 3500],
			sentence18: [60000, 3300],
			sentence19: [63400, 3200],
			sentence20: [72000, 3200],
			sentence21: [75200, 3000],
			sentence22: [78800, 3200],
			sentence23: [82400, 3500],
			sentence24: [86200, 3800],
			sentence25: [90400, 3500],
			sentence26: [94500, 3000],
			sentence27: [98000, 3200],
			sentence28: [102000, 3400],
			sentence29: [106500, 6700],
		}
	},
	{
		name: "jahr",
		url: jahr,
		sprite: {
			sentence1: [0, 4000], 
			sentence2: [4000, 3000], 
			sentence3: [7000, 3000], 
			sentence4: [10500, 3500], 
			sentence5: [14000, 2400], 
			sentence6: [17500, 2500], 
			sentence7: [20500, 3500], 
			sentence8: [24500, 3500], 
			sentence9: [28500, 2500], 
			sentence10: [32000, 3000], 
			sentence11: [36000, 2000], 
			sentence12: [39000, 4000], 
			sentence13: [43000, 3000], 
			sentence14: [47000, 3400], 
			sentence15: [51000, 3900], 
			sentence16: [56000, 3000],
			sentence17: [60000, 3000], 
			sentence18: [63000, 3000], 
			sentence19: [66500, 2500], 
			sentence20: [69500, 2500], 
			sentence21: [73000, 3000], 
			sentence22: [77000, 3300], 
			sentence23: [81000, 4000], 
			sentence24: [85500, 2500], 
			sentence25: [89000, 2000], 
			sentence26: [93000, 3000], 
			sentence27: [100000, 3000], 
			sentence28: [103500, 3500], 
			sentence29: [107500, 2200], 
			sentence30: [110000, 2600], 
			sentence31: [113000, 3000], 
			sentence32: [116500, 3900], 
		}
	}
]
