import { useState } from "react";

import { ReactComponent as Correct } from "../assets/icons/Smiley_Happy.svg";
import { ReactComponent as Incorrect } from "../assets/icons/Smiley_Sad.svg";
import { ReactComponent as Neutral } from "../assets/icons/Smiley_Neutral.svg";

const StatusSmiley = (props: any) => {
  const activeIndex = props.status;
  
  switch(activeIndex)
  {
    case 2:
      return (
        <div>
          <Correct />
        </div>
      );
    case 0:
      return (
        <div>
          <Incorrect />
        </div>
      );
    default:
      return (
        <div>
          <Neutral />
        </div>
      );
  }
}

export default StatusSmiley;