import { ISong } from "../interfaces";

import song1 from "../assets/Lieder/backe_backe_kuchen.mp3";
import song2 from "../assets/Lieder/Geburtstagslied.mp3";
import song3 from "../assets/Lieder/Hoppe hoppe reiter.mp3";
import song4 from "../assets/Lieder/Keine Angst.mp3";
import song5 from "../assets/Lieder/Baum.mp3";
import song6 from "../assets/Lieder/Unterwegs.mp3";

export const SONGS: Array<ISong> = [
	{
		name: "Kuchen",
		url: song1,
		image: "Garten_6_1.svg"
	},
	{
		name: "Geburtstag",
		url: song2,
		image: "Geburtstagsfest_2.svg"
	},
	{
		name: "Reiter",
		url: song3,
		image: "Garten_5.svg"
	},
	{
		name: "Angst",
		url: song4,
		image: "Garten_3.svg"
	},
	{
		name: "Baum",
		url: song5,
		image: "Geburtstagsfest_7.svg"
	},
	{
		name: "Unterwegs",
		url: song6,
		image: "Unterwegs_1_2.svg"
	},
];