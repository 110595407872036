import React, { useState, useEffect } from "react";
import useSound from 'use-sound';
import { useParams } from "react-router-dom";

// constants
import { AUDIO_STATUS } from "../constants";
import { SENTENCESAUDIO } from "../constants/SentenceSprites";

// assets
import { ReactComponent as Play } from "../assets/icons/Play.svg";
import { ReactComponent as Stop } from "../assets/icons/Stop.svg";
import { ReactComponent as Replay } from "../assets/icons/Replay.svg"

const useAudio = (initialSentence:string) => {
  const { topic } = useParams();
  const spriteData = SENTENCESAUDIO.find(words => words.name === topic);
  const sprite = spriteData ? spriteData : SENTENCESAUDIO[0];
  const [sentence, setSentence] = useState(initialSentence);
  const [audioStatus, setAudioStatus] = useState(AUDIO_STATUS.DEFAULT);
  const [play, { stop }] = useSound(sprite.url, {
    sprite: sprite.sprite,
    onend: function() {
      setAudioStatus(AUDIO_STATUS.ENDED);
    }
  });

  useEffect(() => {
      return () => {
        stop();
      };
    },
    [play]
  );

  const changeSentence = (newSentence:string) => {
    stopAudio();
    setSentence(newSentence);
    setAudioStatus(AUDIO_STATUS.DEFAULT);
    play({id: newSentence});
    setAudioStatus(AUDIO_STATUS.PLAYING)
  }

  const stopAudio = () => {
    stop();
    setAudioStatus(AUDIO_STATUS.ENDED);
  }

  const replay = () => {
    play({id: sentence});
    setAudioStatus(AUDIO_STATUS.PLAYING)
  }

  return [audioStatus, replay, stopAudio, changeSentence] as const;
};

export const SentencesAudio = (props:any) => {
  const [sentence, setSentence] = useState(props.sentence);
  const [audioStatus, replay, stopAudio, changeSentence] = useAudio(props.sentence);

  useEffect(() => {
      if(sentence !== props.sentence)
      {
        setSentence(props.sentence);
        changeSentence(props.sentence);
      }
    },
    [sentence, changeSentence, props.sentence]
  );

    switch(audioStatus)
    {
      case AUDIO_STATUS.DEFAULT:
        return (
          <>
            <Play onClick={replay} /> 
          </>
        );
      case AUDIO_STATUS.PLAYING:
        return (
          <>
            <Stop onClick={stopAudio} />
          </>
        );
      case AUDIO_STATUS.ENDED:
        return (
          <>
            <Replay onClick={replay} />
          </>
        );
    };
};
