import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useSound from 'use-sound';

// constants
import { AUDIO_STATUS } from "../constants";
import { WORDSAUDIO } from "../constants/WordSprites";

// assets
import { ReactComponent as Play } from "../assets/icons/Play.svg";
import { ReactComponent as Stop } from "../assets/icons/Stop.svg";
import { ReactComponent as Replay } from "../assets/icons/Replay.svg";

export const useAudio = (initialWord:string) => {
  const { topic } = useParams();
  const spriteData = WORDSAUDIO.find(words => words.name === topic);
  const sprite = spriteData ? spriteData : WORDSAUDIO[0];
  const [word, setWord] = useState(initialWord);
  const [audioStatus, setAudioStatus] = useState(AUDIO_STATUS.DEFAULT);
  const [play, { stop }] = useSound(sprite.url, {
    sprite: sprite.sprite,
    onend: function() {
      setAudioStatus(AUDIO_STATUS.ENDED);
    }
  });

  useEffect(() => {
      return () => {
        stop();
      };
    },
    [play]
  );

  const changeWord = (newWord:string) => {
    stopAudio();
    setWord(newWord);
    setAudioStatus(AUDIO_STATUS.DEFAULT);
    play({id: newWord});
    setAudioStatus(AUDIO_STATUS.PLAYING)
  }

  const stopAudio = () => {
    stop();
    setAudioStatus(AUDIO_STATUS.ENDED);
  }

  const replay = () => {
    play({id: word});
    setAudioStatus(AUDIO_STATUS.PLAYING)
  }

  return [audioStatus, replay, stopAudio, changeWord] as const;
};

export const WordsAudio = (props:any) => {
  const [word, setWord] = useState(props.word);
  const [audioStatus, replay, stopAudio, changeWord] = useAudio(props.word);

  useEffect(() => {
      if(word !== props.word)
      {
        setWord(props.word);
        changeWord(props.word);
      }
    },
    [word, changeWord, props.word]
  );

  switch(audioStatus)
  {
    case AUDIO_STATUS.DEFAULT:
      return (
        <>
          <Play onClick={replay} /> 
        </>
      );
    case AUDIO_STATUS.PLAYING:
      return (
        <>
          <Stop onClick={stopAudio} />
        </>
      );
    case AUDIO_STATUS.ENDED:
      return (
        <>
          <Replay onClick={replay} />
        </>
      );
    };
};
