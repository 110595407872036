import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

// data
import { SENTENCES } from "../../constants/Sentences";

// Components
import { SentencesAudio } from "../../components/SentencesAudio";

// assets
import { ReactComponent as Close } from "../../assets/icons/Close.svg";
import { ReactComponent as Next } from "../../assets/icons/Forward.svg";
import { ReactComponent as Back } from "../../assets/icons/Back.svg";

// Styles
import { Mobile, TabletAndDesktop } from "../../app/mediaQueries";
import styled from 'styled-components';
const Main = styled.div`
  width: 100%;
  height: 100vh;
`;
const SentenceContainer = styled.div`
  height: 88vh;
  padding-bottom: 10vh;
`;
const ImageContainer = styled.div`
  display: flex;
  float: left;
  width: 50%;
  height: 90%;
  > img {
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }
`; 
const TextContainer = styled.div`
  height: 10%;
  > h2 {
    margin: 0;
  }
`; 
const SentenceContainerMobile = styled.div`
  height: 82vh;
  padding-top: 2vh;
  padding-bottom: 8vh;
`;
const ImageContainerMobile = styled.div`
  display: flex;
  width: 100%;
  height: 45%;
  > img {
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }
`; 
const TextContainerMobile = styled.div`
  height: 10%;
`; 
const Header = styled.div`
  position: fixed;
  height: 8vh;
  width: 100%;
  > div {
    padding: 2vh 2vh 0 2vh;
  }
`;
const CloseContainer = styled.div`
  float: right;
  width: 40px;
  svg {
    transform: scale(0.9);
    &:hover {
      transform: scale(1);
    }
  }
`;
const Footer = styled.div`
  position: fixed;
  bottom: 0;
  height: 10vh;
  width: 100%;
  > div {
    width: 30vh;
    margin: 0 auto;
    padding-bottom: 2vh;
  }
`;
const ButtonContainer = styled.div`
  float: left;
  width: 8vh;
  height: 8vh;
  padding: 0 1vh;
`;

const Sentences = () => {
  const { topic, group } = useParams();
  const [index, setIndex] = useState(0);
  const topicData = SENTENCES.find(topics => topics.name === topic);
  let sentences = topicData ? topicData.sentences.filter(groups => groups.group === group) : SENTENCES[0].sentences;
  sentences = sentences?.length > 0 ? sentences : SENTENCES[0].sentences;

  const next = () => setIndex(index + 1);
  const previous = () => setIndex(index - 1);

  return (
  	<>
      <Main>
        <Header>
          <div>
            <CloseContainer>
              <Link to={`/uebungen/thema/${topic}/saetze`}>
                <Close />
              </Link>
            </CloseContainer>
          </div>
        </Header>

        <TabletAndDesktop>
          <SentenceContainer>
            <ImageContainer>
              <img src={require(`../../assets/Bilder/${sentences[index].image1}.svg`)} alt={sentences[index].image1} />
            </ImageContainer>
            <ImageContainer>
              <img src={require(`../../assets/Bilder/${sentences[index].image2}.svg`)} alt={sentences[index].image2} />
            </ImageContainer>
            <TextContainer>
              <h2>{sentences[index].sentence}</h2>
            </TextContainer>
          </SentenceContainer>
        </TabletAndDesktop>

        <Mobile>
          <SentenceContainerMobile>
            <ImageContainerMobile>
              <img src={require(`../../assets/Bilder/${sentences[index].image1}.svg`)} alt={sentences[index].image1} />
            </ImageContainerMobile>
            <ImageContainerMobile>
              <img src={require(`../../assets/Bilder/${sentences[index].image2}.svg`)} alt={sentences[index].image2} />
            </ImageContainerMobile>
            <TextContainerMobile>
              <h2>{sentences[index].sentence}</h2>
            </TextContainerMobile>
          </SentenceContainerMobile>
        </Mobile>

        <Footer>
          <div>
            <ButtonContainer>
              <Back onClick={() => previous()} style={{display: index === 0 ? "none" : "initial" }} />
            </ButtonContainer>
            <ButtonContainer>
              <SentencesAudio sentence={sentences[index].spriteId} />
            </ButtonContainer>
            <ButtonContainer>
              <Next onClick={() => next()} style={{display: index >= sentences.length - 1 ? "none" : "initial" }} />
            </ButtonContainer>
          </div>
        </Footer>
        
      </Main>
	  </>
  );
};

export default Sentences;
