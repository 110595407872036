import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useSound from 'use-sound';

// Assets
import { ReactComponent as Story1 } from "../../assets/Figuren/1_Molnar.svg";
import { ReactComponent as Story2 } from "../../assets/Figuren/2_Tina.svg";
import { ReactComponent as Story3 } from "../../assets/Figuren/3_Eduardo.svg";
import { ReactComponent as Story4 } from "../../assets/Figuren/4_Pleta.svg";
import { ReactComponent as Story5 } from "../../assets/Figuren/5_Kuchen.svg";
import { ReactComponent as Play } from "../../assets/icons/Play.svg";
import { ReactComponent as Games } from "../../assets/icons/Spiele.svg";
import { ReactComponent as Impressum } from "../../assets/icons/Kompass.svg";

// Audio
import Intro from "../../assets/Intro.mp3";

// Styles
import { Mobile, TabletAndDesktop } from "../../app/mediaQueries";
import styled from 'styled-components';

const Main = styled.div`
  display: block;
  height: 100vh;
  align-items: center;
  width: 100%;
  svg {
    max-height: 100%;
    max-width: 100%;
    transform: scale(0.9);
    &:hover {
      transform: scale(1);
    }
  }
  .zoomed {
    svg {
      transform: scale(1.1);
    }
  }
`;
const Container22 = styled.div`
  float: left;
  width: 22%;
  height: 70vh;
`;
const Pleta = styled.div`
  float: left;
  width: 34%;
  height: 70vh;
  svg {
    max-height: 100%;
    max-width: 100%;
    transform: scale(1);
    &:hover {
      transform: scale(1.1);
    }
  }
  .zoomed {
    svg {
      transform: scale(1.2);
    }
  }
`;
const Container30Height = styled.div`
  height: 30vh;
  width: 100%;
  svg {
    max-width: 90%;
  }
`;
const Container70Height = styled.div`
  height: 70vh;
  width: 100%;
`;

const Overlay = styled.div`
  position: absolute;
  height: 100vh;
  width: 100%;
  background: rgba(30,30,30,0.5);
  z-index: 2;
`;
const PlayContainer = styled.div`
  cursor: pointer;
  margin: 38vh auto;
  height: 15vh;
  width: 15vh;
  background: white;
  padding: 1vh;
  border-radius: 15px;
  svg {
    max-height: 100%;
  }
`;

const MainMobile = styled.div`
  align-items: center;
  width: 100%;
  height: 100vh;
  svg {
    transform: scale(0.9);
    height: 33vh;
    max-width: 100%;
    &:hover {
      transform: scale(1);
    }
  }
  .zoomed {
    svg {
      transform: scale(1.1);
    }
  }
`;
const Container50 = styled.div`
  float: left;
  width: 50%;
`;
const ScaleDown = styled.div`
  .zoomed {
    svg {
      transform: scale(0.7);
    }
  }
  svg {
    transform: scale(0.6);
    &:hover {
      transform: scale(0.8);
    }
  }
`;
const GamesContainer = styled.div`
  float: right;
  width: 8%;
  padding: 1%;
  > a {
    display: block;
    height: 10vh;
    > svg {
      transform: scale(0.8);
      &:hover {
        transform: scale(0.9);
      }
    }
  }
`;
const MobileGamesContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 10%;
  padding: 2%;
  height: 8%;
  z-index: 1;
  > a {
    height: 100%;
    display: block;
    > svg {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;

const ImpressumContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10%;
  padding: 2%;
  height: 8%;
  z-index: 1;
  > a {
    height: 100%;
    display: block;
    > svg {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;

const useAudio = () => {
  const navigate = useNavigate();
  const [playing, setPlaying] = useState(false);
  const [activeChapter, setActiveChapter] = useState(0);
  const [play, { stop, sound }] = useSound(
    Intro,{
    onend: function() {
      navigate("/geschichte/molnar");
    }
  });
  const chapterSeconds: Array<number> = [2, 21, 40.5, 70, 89];

  const start = () => setPlaying(true);

  useEffect(() => {
    if (playing)
    {
      play();
      setTimeout(function() {zoomChapter(activeChapter); }, 500);
    }
  }, [playing])

  const zoomChapter = (index:number) => {
    if(sound != null) {
      if (sound.seek() > chapterSeconds[index]) {
        index++;
        setActiveChapter(index);
      }

      if (sound.playing()) {
        setTimeout(function() { zoomChapter(index); }, 500);
      }
    }
  }
  return [activeChapter, stop, playing, start] as const;
}

const Landing = () => {
  const [activeChapter, stop, playing, start] = useAudio();

  return (
  	<>
      <TabletAndDesktop>
        {
          playing ?
            <></>
          :
            <Overlay onClick={start}>
              <PlayContainer>
                <Play />
              </PlayContainer>
            </Overlay>
        }
        <Main>
          <Container30Height className={activeChapter === 5 ? 'zoomed' : ''}>
            <Link to={`/geschichte/geburtstagsfest`} onClick={() => { stop(); }}>
              <Story5 />
            </Link>

            <GamesContainer>
              <Link to={`/uebungen`} onClick={() => { stop(); }}>
                <Games />
              </Link>
            </GamesContainer>
          </Container30Height>
          <Container70Height>
            <Container22 className={activeChapter === 1 ? 'zoomed' : ''}>
              <Link to={`/geschichte/molnar`} onClick={() => { stop(); }}>
                <Story1 />
              </Link>
            </Container22>
            <Container22 className={activeChapter === 2 ? 'zoomed' : ''}>
              <Link to={`/geschichte/tina`} onClick={() => { stop(); }}>
                <Story2 />
              </Link>
            </Container22>
            <Container22 className={activeChapter === 3 ? 'zoomed' : ''}>
              <Link to={`/geschichte/eduardo`} onClick={() => { stop(); }}>
                <Story3 />
              </Link>
            </Container22>
            <Pleta className={activeChapter === 4 ? 'zoomed' : ''}>
              <Link to={`/geschichte/pleta`} onClick={() => { stop(); }}>
                <Story4 />
              </Link>
            </Pleta>
          </Container70Height>

          <ImpressumContainer>
            <Link to={`/impressum`} onClick={() => { stop(); }}>
              <Impressum />
            </Link>
          </ImpressumContainer>

        </Main>
      </TabletAndDesktop>

      <Mobile>
        {
          playing ?
            <></>
          :
            <Overlay onClick={start}>
              <PlayContainer>
                <Play />
              </PlayContainer>
            </Overlay>
        }
        <MainMobile>
          <MobileGamesContainer>
            <Link to={`/uebungen`} onClick={() => { stop(); }}>
              <Games />
            </Link>
          </MobileGamesContainer>

          <div className={activeChapter === 5 ? 'zoomed' : ''}>
            <Link to={`/geschichte/geburtstagsfest`} onClick={() => { stop(); }}>
              <Story5 />
            </Link>
          </div>
          <Container50 className={activeChapter === 1 ? 'zoomed' : ''}>
            <Link to={`/geschichte/molnar`} onClick={() => { stop(); }}>
              <Story1 />
            </Link>
          </Container50>

          <Container50>
            <ScaleDown className={activeChapter === 2 ? 'zoomed' : ''}>
              <Link to={`/geschichte/tina`} onClick={() => { stop(); }}>
                <Story2 />
              </Link>
            </ScaleDown>
          </Container50>

          <Container50>
            <ScaleDown className={activeChapter === 3 ? 'zoomed' : ''}>
              <Link to={`/geschichte/eduardo`} onClick={() => { stop(); }}>
                <Story3 />
              </Link>
            </ScaleDown>
          </Container50>

          <Container50>
            <ScaleDown className={activeChapter === 4 ? 'zoomed' : ''}>
              <Link to={`/geschichte/pleta`} onClick={() => { stop(); }}>
                <Story4 />
              </Link>
            </ScaleDown>
          </Container50>

          <ImpressumContainer>
            <Link to={`/impressum`} onClick={() => { stop(); }}>
              <Impressum />
            </Link>
          </ImpressumContainer>
        </MainMobile>
      </Mobile>
	  </>
  );
};

export default Landing;
