import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

// data
import { WORDS } from "../../constants/Words";

// Components
import { WordsAudio } from "../../components/WordsAudio";

// assets
import { ReactComponent as Close } from "../../assets/icons/Close.svg";
import { ReactComponent as Next } from "../../assets/icons/Forward.svg";
import { ReactComponent as Back } from "../../assets/icons/Back.svg";

// Styles
import styled from 'styled-components';
const Main = styled.div`
  width: 100%;
  height: 100vh;
`;
const WordContainer = styled.div`
  display: flex;
  height: 80vh;
  width: 100%;
  padding-top: 2vh;
  > div {
    width: 100%;
    max-height: 80vh;
    margin: auto 0;
    > img {
      max-height: 80vh;
      max-width: 100%;
    }
    > h2 {
      font-size: 5vh;
      margin: 0;
    }
  }
`;
const Header = styled.div`
  position: fixed;
  height: 8vh;
  width: 100%;
  > div {
    padding: 2vh 2vh 0 2vh;
  }
`;
const CloseContainer = styled.div`
  float: right;
  width: 40px;
  svg {
    transform: scale(0.9);
    &:hover {
      transform: scale(1);
    }
  }
`;
const Footer = styled.div`
  position: fixed;
  bottom: 0;
  height: 10vh;
  width: 100%;
  > div {
    width: 30vh;
    margin: 0 auto;
    padding-bottom: 2vh;
  }
`;
const ButtonContainer = styled.div`
  float: left;
  width: 8vh;
  height: 8vh;
  padding: 0 1vh;
`;

const Words = () => {
  const [index, setIndex] = useState(0);
  const { topic, group } = useParams();

  const topicData = WORDS.find(topics => topics.name === topic);
  let words = topicData ? topicData.words.filter(groups => groups.group === group) : WORDS[0].words;
  words = words?.length > 0 ? words : WORDS[0].words;

  const next = () => setIndex(index + 1);
  const previous = () => setIndex(index - 1);

  return (
  	<>
      <Main>
        <Header>
          <div>
            <CloseContainer>
              <Link to={`/uebungen/thema/${topic}/worte`}>
                <Close />
              </Link>
            </CloseContainer>
          </div>
        </Header>

        <WordContainer>
          <div>
            <img src={require(`../../assets/Bilder/${words[index].image}.svg`)} alt={words[index].image} />
            <h2>{words[index].word}</h2>
          </div>
        </WordContainer>

        <Footer>
          <div>
            <ButtonContainer>
              <Back onClick={() => previous()} style={{display: index === 0 ? "none" : "initial" }} />
            </ButtonContainer>
            <ButtonContainer>
              <WordsAudio word={words[index].spriteId} />
            </ButtonContainer>
            <ButtonContainer>
              <Next onClick={() => next()} style={{display: index >= words.length - 1 ? "none" : "initial" }} />
            </ButtonContainer>
          </div>
        </Footer>
      </Main>
	  </>
  );
};

export default Words;
