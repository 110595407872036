import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useSound from 'use-sound';

// assets
import { ReactComponent as Play } from "../assets/icons/Play.svg";
import { ReactComponent as Pause } from "../assets/icons/Pause.svg";
import { ReactComponent as Replay } from "../assets/icons/Replay.svg";
import { ReactComponent as Next } from "../assets/icons/Forward.svg";
import { ReactComponent as Back } from "../assets/icons/Back.svg";

// Data
import { STORY } from "../constants/Story";

import styled from 'styled-components';
const ImageContainer = styled.div`
  aspect-ratio: 4 / 3;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: 100%;
  max-height: 80vh;
  pointer-events: none;

  div {
    position: absolute;
    top: 4%;
    right: 0%;
    width: 30%;
    height: 30%;
    img {
      width: 100%;
      max-height: 100%;
    }
    &.top-left {
      left: 0%;
      width: 28%;
      height: 28%;
    }
    &.top-right-small {
      width: 25%;
      height: 25%;
    }
  }
`;
const StoryContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  > div {
    width: 100%;
    max-height: 80vh;
    margin: auto 0;
    > img {
      max-height: 80vh;
      max-width: 100%;
    }
  }
`;
const Footer = styled.div`
  position: fixed;
  bottom: 0;
  height: 10vh;
  width: 100%;
`;
const ButtonContainer = styled.div`
  float: left;
  width: 8vh;
  height: 8vh;
  padding: 0 1vh;
  > div.next {
  	height: 100%;
  	width: 100%;
  	img {
	  	max-height: 100%;
	  	max-width: 100%;
  	}
  }

  .blink {
  	animation: blinker 2s step-start infinite;
	
		@keyframes blinker {
	  50% {
	    opacity: .5;
	  }
	}
  }
`;

const NavigationContainer = styled.div`
    width: 30vh;
    margin: 0 auto;
    padding-bottom: 2vh;
`;

const compareNumbers = (a:number, b:number) => {
  return a - b;
}

const useAudio = (chapter:number, story: string) => {
  const [playing, setPlaying] = useState(true);
  const [ended, setEnded] = useState(false);
  const [image, setImage] = useState("");
  const [imagePosition, setImagePosition] = useState("");
  const [imageIndex, setImageIndex] = useState(0);
  const storypart = STORY.find(stories => stories.name === story);
  const chapters = storypart ? storypart.chapters : STORY[0].chapters;
  const url = chapters[chapter].url;
  const images = chapters[chapter].images;
  const imageSeconds = (images.map(i => i.showAfter)).sort(compareNumbers);
  const [play, { pause, stop, sound }] = useSound(url, {
    onend: function() {
      setPlaying(false);
      setEnded(true);
    }
  });

  useEffect(() => {
    toggle();
      return () => {
        reset();
        stop();
      };
    },
    [play]
  );

  // TODO Autoplay bei Kapitel wechsel

  const toggle = () => setPlaying(!playing);

  const reset = () => {
    stop();
    setImageIndex(0);
    setImage("");
    setImagePosition("");
    setPlaying(true);
    setEnded(false);
  }

  useEffect(() => {
      if (playing) 
      {
        play();
        setTimeout(function() {additionalImageDisplay(imageIndex); }, 500);
      } 
      else 
      {
        pause();
      }
    },
    [playing, ended]
  );

  const additionalImageDisplay = (index:number) => {
    if(sound != null && images.length > index) {
      if (sound.seek() > imageSeconds[index]) {
        setImage(images[index].image);
        setImagePosition(images[index].position);
        index++;
        setImageIndex(index);
      }

      if (sound.playing()) {
        setTimeout(function() { additionalImageDisplay(index); }, 500);
      }
    }
  }

  return [playing, toggle, ended, image, reset, imagePosition] as const;
};

type StoryParams = {
  story: string;
};

export const StoryNavigation = (props:any) => {
  const [chapter, setChapter] = React.useState(0);
  const { story } = useParams<StoryParams>();
  const storypart = STORY.find(stories => stories.name === story);
  const [playing, toggle, ended, image, reset, imagePosition] = useAudio(chapter, storypart ? storypart.name : "");
  const chapters = storypart ? storypart.chapters : STORY[0].chapters;
  const nextImage = storypart ? storypart.nextImage : STORY[0].nextImage;
  const nextUrl = storypart ? storypart.nextUrl : STORY[0].nextUrl;

  const nextStory = () => {
  	setChapter(0);
  	window.location.href = nextUrl;
  }
  const next = () => setChapter(chapter + 1);
  const previous = () => setChapter(chapter - 1);

  return (
  	<>
  		<StoryContainer>
	          <div>
	            <img src={require(`../assets/Geschichte/${chapters[chapter].image}.svg`)} alt={chapters[chapter].image} />
	          </div>
        </StoryContainer>

  		<Footer>
		    <NavigationContainer>

		      <ButtonContainer> 
		        <Back onClick={() => previous()} style={{display: chapter === 0 ? "none" : "initial" }} />
		      </ButtonContainer>
		      <ButtonContainer>
		        {
		          image.length > 0 ?
		            <ImageContainer>
		              <div className={imagePosition}>
		                <img src={require(`../assets/Geschichte/${image}.svg`)} alt={image} />
		              </div>
		            </ImageContainer>
		          :
		            <></>
		        }

		        {
		          playing ?
		            <Pause onClick={toggle} />
		          :
		          ended ?
		            <Replay onClick={reset} />
		          :
		            <Play onClick={toggle} /> 
		        }
		      	</ButtonContainer>
		      	<ButtonContainer>
		       		{
	       				chapter >= chapters.length - 1 ?
		       				<div className={ended ? "next blink" : "next"} onClick={() => nextStory()}>
       							<img src={require(`../assets/Figuren/${nextImage}.svg`)} alt={nextImage} />
   							</div>
	       				:
	       					<Next className={ended ? "blink" : ""} onClick={() => next()} />
		       		}
	       		</ButtonContainer>
		    </NavigationContainer>
	    </Footer>
    </>
  );
};
